import React from 'react';
import Table from '../Layout/Table';

import moment from 'moment';

class DataTable extends Table {
    constructor(props) {
        super(props);
    }

    renderRow(data) {
        const {
            name,
            end_at,
            rows,
            size_in_kb,
            version,
        } = data;

        const {
            selectedRows = [] 
        } = this.state;

        const {
            hide_checkboxes = false,
        } = this.props;

        const is_selected = selectedRows.includes(name);

        return (
            <tr key={name}>
                {!hide_checkboxes && <td>
                    <input checked={is_selected} type="checkbox" onChange={(e) => {
                        this.selectRow(name);
                    }}/>
                </td>}
                <td className="table-name">{ name }</td>
                <td>
                    {version}
                </td>
                <td>
                    {moment(end_at).format('DD MMM YY')}
                </td>
                <td>
                    {rows}
                </td>
                <td>
                    
                    <a className="button small icon mr" onClick={(e) => {
                        e.preventDefault();
                    }}>
                        <span className="download"></span> Download CSV
                    </a>

                    <a className="button small icon" onClick={(e) => {
                        e.preventDefault();
                    }}>
                        <span className="download"></span> Download JSON
                    </a>
                </td>
            </tr>
        )
    }
}

export default DataTable;