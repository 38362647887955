import React from 'react';
import moment from 'moment';

function Table (props) {

    const {
        data = [],
        answers,
        update_answers = () => {}, 
    } = props;

    const dob = (data.filter(p => p.name === 'Date of birth')[0] || {}).value;
    const age = moment().diff(dob, 'years');
    const height = (data.filter(p => p.name === 'Height')[0] || {}).value;
    const positions = (data.filter(p => p.name === 'Position(s)')[0] || {}).value;

    return (
        <div className="card position">
            <table>
                <thead>
                    <tr>
                        <th>Age</th>
                        <th>Positions</th>
                        <th>Height</th>
                        <th>{ answers.positions && <button className="active show_number">-1</button>}</th>
                    </tr>
                </thead>
                <tbody>
                    { answers.positions && 
                        <tr>
                            <td>
                                <span>
                                    <b>{age}</b>
                                </span>
                            </td>
                            <td>
                                <b>{positions}</b>
                            </td>
                            <td><b>{(height || '').split('(')[0]}</b></td>
                        </tr>
                    }
                    { !answers.positions && 
                        <tr className='strike'>
                            <td>
                                <b>-</b>
                            </td>
                            <td>
                                <b>-</b>
                            </td>
                            <td>
                                <b>-</b>
                            </td>
                            <td className="button">
                                <button className='' onClick={() => update_answers({
                                    type: 'positions',
                                    data: true,
                                })}>?</button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export default Table;
