import React, { Component } from 'react';
import main from '../../../hoc/main';
import { sample, get, isEmpty } from 'lodash';

import Select from 'react-select';

import data from '../data';

import BonusSelect from '../components/bonus/Select';
import Clue from '../components/clue/Index';

import AwardsTable from '../components/awards/Table';
import ClubsTable from '../components/clubs/Table';
import InternationalTable from '../components/international/Table';
import PositionTable from '../components/position/Table';

const base_clues = {
    clubs: [],
    international: false,
    awards: false,
    positions: false,
    low_apps: false,
}

function create_score(clues = {}) {
    const { 
        international = false,
        awards = false,
        positions = false,
        clubs = [],
        guesses = []
    } = clues;
    let score = 10;
    if (international) score = score - 1;
    if (awards) score = score - 1;
    if (positions) score = score - 1;
    score = score - clubs.length;
    return score < 0 ? 0 : score;
}

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ids: [],
            data: [],
            selected: {},
            clues : base_clues,
            is_successful: false,
            is_failure: false,
        }
        this.update_clues = this.update_clues.bind(this);
        this.update_guesses = this.update_guesses.bind(this);
        this.submit_guess = this.submit_guess.bind(this);
        this.clear_modal = this.clear_modal.bind(this);
        this.new_game = this.new_game.bind(this);
        this.quit_game = this.quit_game.bind(this);
    }

    async componentDidMount() {
        const footballers = data('eng');
        const selected_slug = sample(Object.keys(footballers.data));
        this.setState({
            ids: footballers.ids,
            data: footballers,
            selected_slug,
            selected: footballers.data[selected_slug],
        });
    }

    update_clues ({ data, type }) {
        if (['clubs',
            'international',
            'awards',
            'positions',
            'low_apps'
        ].includes(type)) {
            this.setState({
                clues: {
                    ...this.state.clues,
                    [type]: type === 'clubs' ? [ data, ...this.state.clues.clubs ] : data,
                }
            })
        }
    }

    update_guesses (guess) {
        const { selected_slug } = this.state;
        if (!guess) return;
        const guess_slug = guess.value;
        this.setState({
            guess_slug,
            is_successful: false, 
            is_failure: false,
        });
    }

    submit_guess () {
        const { selected_slug, guess_slug } = this.state;
        if (selected_slug === guess_slug) {
            this.setState({ 
                is_successful: true,
            }, () => {
                document.body.classList.add('block-scroll');
            });

        } else {
            this.setState({ 
                is_failure: true,
            }, () => {
                document.body.classList.add('block-scroll');
            });
        }
    }

    clear_modal () {
        this.setState({
            is_successful: false,
            is_failure: false,
        }, () => {
            document.body.classList.remove('block-scroll');
        });
    }

    new_game () {
        window.location.reload();
    }

    quit_game () {
        this.setState({
            is_quit: true,
            is_successful: false,
            is_failure: false,
        }, () => {
            document.body.classList.remove('block-scroll');
        });
    }

    render() {
        const { data = {}, selected = {}, clues, is_successful, is_failure, is_quit } = this.state;
        const { clubs = [], national = [], awards = [], personal = [] } = selected;
        const score = create_score(clues);

        return (
            <div>
                { data.ids && 
                    <div className="guess-container">

                        <div className="guess-input">
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                name="footballer"
                                placeholder={'Guess the footballer...'}
                                onChange={this.update_guesses}
                                options={data.ids}
                                styles={{
                                    placeholder: (base) => ({ 
                                        ...base,
                                    })
                                }}
                            />
                        </div>

                        <div onClick={this.submit_guess} className="guess-submit">
                            <button>Guess</button>
                        </div>

                        <div className="guess-score">
                            <span>{score}</span>
                        </div>
                    </div>
                } 

                <div className="container">

                    <PositionTable 
                        data={personal}
                        answers={clues}
                        update_answers={this.update_clues}
                    />

                    <ClubsTable 
                        data={clubs}
                        answers={clues}
                        update_answers={this.update_clues}
                    />

                    <InternationalTable 
                        data={national}
                        answers={clues}
                        update_answers={this.update_clues}
                    />

                    <AwardsTable 
                        data={awards}
                        answers={clues}
                        update_answers={this.update_clues}
                    />

                </div>

                {is_successful && !isEmpty(selected) &&
                    <div className="modal">
                        <div className="modal-content success">
                            <p class="heavy">Correct!</p>
                            <p class="medium">You scored <b>{score}</b>/10</p>
                            <p class="light"> It was <b>{get(selected, 'full_name')}</b></p>
                            <button onClick={this.new_game}>New Game</button>
                        </div>
                    </div>
                }

                {is_failure && !isEmpty(selected) &&
                    <div className="modal">
                        <div className="modal-content failure">
                            <p class="heavy">Wrong!</p>
                            <p class="medium">&nbsp;</p>
                            <button onClick={this.clear_modal}>Try Again</button>
                            <button onClick={this.quit_game}>Give Up</button>
                        </div>
                    </div>
                }

                {is_quit && !isEmpty(selected) &&
                    <div className="modal">
                        <div className="modal-content failure">
                            <p class="heavy">You Quit!</p>
                            <p class="medium">You scored <b>0</b>/10</p>
                            <p class="light"> It was <b>{get(selected, 'full_name')}</b></p>
                            <button onClick={this.new_game}>New Game</button>
                        </div>
                    </div>
                }   

            </div>
        );
    }
}

export default main(Home, {
    is_private: false,
});