import React, { Component } from 'react';
import base from '../../config';

class Timeout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { email } = this.props;
        const timeout = (base.allow.poll_timeout / 1000);
        return (
            <div id="timeout" className="wf">
                <h2>Your email link timed out</h2>
                <p>Sent to: {email}</p>
                <p>Sorry, you failed to be authorised because you didn't click on the email link within <mark>{timeout}</mark> seconds.</p>
                <p>
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.props.set_auth_status('START');
                    }}>
                        Try again
                    </button>
                </p>
            </div>
        );
    }
}
export default Timeout;