import React, { Component } from 'react';

import { sign_out } from '../../../../auth';
import { getId } from '../../../../utils/url';

import { Link } from 'react-router-dom';

class TopNav extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { history } = this.props;
        const id = getId(history);
        const { pathname } = history.location;

        function is_active(name) {
            if (name === 'projects' && pathname === '/projects') return 'projects active';
            if (name === 'data' && pathname.includes('/project/data')) return 'data active';
            if (name === 'design' && pathname.includes('/version/design')) return 'design active';
            if (name === 'tests' && pathname.includes('/version/tests')) return 'tests active';
            return name;
        }

        function is_show_project_tier() {
            if (pathname.includes('/project/')) return true;
            return false;
        }

        function is_show_version_tier() {
            if (pathname.includes('/version/')) return true;
            return false;
        }

        return (
            <div className="top-nav">

                <div className="top-tier">
                    <div className="logo-nav">
                        <div className="logo-container v-small">
                            <h1 className="logo"></h1>
                        </div>
                    </div>
                    <ul className="links-nav">
                        <li className="home"><a href="">Home</a></li>
                        <li className={is_active('projects')}>
                                <Link to={'/projects'}>Projects</Link>
                        </li>
                        <li className="reviews"><a href="">Reviews</a></li>
                        <li className="data">
                                <Link to={'/data'}>Data</Link>
                        </li>
                        <li className="settings"><a href="">Settings</a></li>
                    </ul>
                    <div className="search-nav">
                        <input type="text" placeholder="Search projects" />
                        <span className="search-icon"></span>
                    </div>
                    <div className="notification-nav">
                        <span className="notify-icon"></span>
                        <span className="notify-count">2</span>
                    </div>
                    <div className="avatar-nav">
                        <span onClick={(e) => {
                            e.preventDefault();
                            sign_out();
                        }} className="avatar-icon">CR</span>
                    </div>
                </div>

                 {is_show_project_tier() &&
                    <div className="project-tier">
                        <div className="breadcrumb-nav"><span>Project</span> &gt; <a href="" className="active">Model Name</a> <span className="breadcrumb-divide">|</span></div>
                        <ul className="links-nav">
                            <li className="versions"><a href="">Versions</a></li>
                            <li className="team"><a href="">Team</a></li>
                            <li className="reviews"><a href="">Reviews</a></li>
                            <li className={is_active('data')}>
                                    <Link to={`/project/data/${id}`}>Data</Link>
                            </li>
                            <li className="logs"><a href="">Logs</a></li>
                            <li className="settings"><a href="">Settings</a></li>
                        </ul>
                        <div className="try">
                            <a className="button small" href="">Try Latest</a>
                            {/* <a href="">Try Live</a> */}
                        </div>
                    </div>
                }

                {is_show_version_tier() &&
                    <div className="version-tier">
                        <div className="breadcrumb-nav"><span>Version</span> &gt; <a className="active" href="">Model Name</a> &gt; <a href="" className="version active">23</a> <span className="breadcrumb-divide">|</span> </div>
                        <ul className="links-nav">
                            <li className={is_active('design')}>
                                <Link to={`/version/design/${id}`}>Designer</Link>
                            </li>
                            <li className={is_active('tests')}>
                                <Link to={`/version/tests/${id}`}>Tests</Link>
                            </li>
                            <li className="reviews"><a href="">Review</a></li>
                            <li className="publish"><a href="">Export</a></li>
                        </ul>
                        <div className="try">
                            {/*<a className="button secondary small" href="">Try Version</a>*/}
                        </div>
                    </div>
                }

            </div >
        );
    }
}

export default TopNav;