import React, { Component } from 'react';
import main from '../../../../hoc/main';

import TopNav from '../../components/Layout/TopNav';

import mock_quote from '../../data/quote';
import mock_quotes from '../../data/quotes';
import mock_version from '../../data/version';

class ProjectLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.selectQuote = this.selectQuote.bind(this);
    }

    async componentDidMount() {
    }

    selectQuote(quote_reference) {
        this.setState({
            isFetchingQuotes: true,
        });

        setTimeout(() => {
            this.setState({
                isFetchingQuotes: false,
                quote: mock_quote,
            });
        }, 500)
    }

    render() {

        const { 
            isFetching: isFetchingQuotes = false,
            data: quotes = mock_quotes,
            selected: quote = mock_quote,
        } = this.props.swallow_quotes || {};

        const { 
            isFetching: isFetchingVersions = false,
            data: versions = [],
            selected: version = mock_version,
        } = this.props.swallow_versions || {};

        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} />

                <section className="main-content slide-left">

                    <div className="logs-area">
                        
                        <div className="logs-area-left">
s
                        </div>

                        <div className="logs-area-right">

                        </div>

                    </div>

                </section>

            </div>
        );
    }
}

export default main(ProjectLogs, {
    is_private: true,
});