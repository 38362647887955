import React, { Component } from 'react';
import { unique_id } from '../../utils/index';

class Table extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            selectedRows: [],
            highlightRow: null,
            searchTerm: '',
        }

        this.renderRow = this.renderRow.bind(this);
        this.renderHeader = this.renderHeader.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.selectAllRows = this.selectAllRows.bind(this);
        this.deleteItems = this.deleteItems.bind(this);
        this.disableItems = this.disableItems.bind(this);

        this.filterItems = this.filterItems.bind(this);
    }


    selectRow (id) {
        let { selectedRows } = this.state;
        const is_selected = selectedRows.includes(id);
        let new_selected;

        if (is_selected) {
            new_selected = selectedRows.filter(s => s !== id);
        } else {
            new_selected = [ ...selectedRows, id ];
        }
        
        this.setState({
            selectedRows: new_selected,
        });
    }

    selectAllRows (is_selected = false) {
        let { componentData, items = componentData.items  } = this.props;
        if (is_selected) {
            this.setState({ selectedRows : [] });
        } else {
            this.setState({ selectedRows : items.map(t => t.id) });
        }
    }

    deleteItems (ids = []) {
        let { updateComponent, componentData, items = componentData.items  } = this.props;
        const new_items = items.filter(t => !ids.includes(t.id));
        updateComponent({
            id: componentData.id, 
            data: {
                ...componentData,
                items: new_items,
            }
        });
    }

    disableItems (ids = []) {
        let { updateComponent, componentData, items = componentData.items  } = this.props;
        const new_items = items.map(t => {
            if (ids.includes(t.id)) return {
                ...t,
                disabled: t.disabled ? false : true,
            }
            return t;
        });
        updateComponent({
            id: componentData.id, 
            data: {
                ...componentData,
                items: new_items,
            }
        });
    }

    filterItems({ 
        searchTerm,
        items, 
    }) {
        if (searchTerm.length < 3) return items;
        const new_items = items.filter(i => {
            return i.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
        return new_items;
    }

    renderSearch({
        placeholder = 'Search Exclusions',
        searchTerm = '',
    }) {
        return (
            <div className="table-control-panel">
                <div className="table-control-panel-left">
                    <div className="table-search">
                        <input 
                            type="text" 
                            placeholder={placeholder} 
                            value={searchTerm} 
                            onChange={(e) => {
                            e.preventDefault();
                            this.setState({
                                searchTerm: e.target.value,
                            })
                        }} />
                        <span className="search-icon"></span>
                    </div>
                </div>
                <div className="table-control-panel-right">
                    <a href="#" className="button grey small mr" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            searchTerm: '',
                        })
                    }}> Reset </a>
                </div>
            </div>
        )
    }
    
    renderHeader() {
        const { headers = [], hide_checkboxes = false } = this.props;
        return (
            <tr>
                {!hide_checkboxes && <th className="table-checkbox"><input 
                    type="checkbox" 
                    onClick={(e) => {
                        this.selectAllRows(!e.target.checked);
                    }}/>
                </th>}
                {headers.map(h => {
                    return (
                        <th className={`table-${h.toLowerCase()}`}>{h}</th>
                    )
                })}
                <th className="table-controls">&nbsp;</th>
            </tr>
        )
    }

    renderRow(data) {
        const {
            name,
            id,
            disabled,
        } = data;

        const {
            hide_checkboxes = false,
        } = this.props;

        const {
            selectedRows = [] 
        } = this.state;

        const is_selected = selectedRows.includes(id);

        return (
            <tr key={id} className={disabled ? 'disabled' : ''}>
                {!hide_checkboxes && 
                    <td>
                        <input checked={is_selected} type="checkbox" onChange={(e) => {
                            this.selectRow(id);
                        }}/>
                    </td>
                }
                <td className="table-name">{ name }</td>
                <td>
                    <a className="button small grey icon" onClick={(e) => {
                        e.preventDefault();
                        this.deleteItems([ id ]);
                    }}>
                        <span className="cross"></span> Delete
                    </a>

                    <a className="button small secondary" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            highlightRow: id,
                        })
                        this.props.selectItem(data);
                    }}>
                        Edit
                    </a>
                </td>
            </tr>
        )
    }

    render() {
        /** Necessary
         * 
         * headers
         * items
         * placeholder
         *
         */

        /**
         * Optional
         * 
         * componentData - for designing components
         */

        let {
            placeholder = 'Search Exclusions',
            componentData,
            items = componentData.items,
        } = this.props;

        const { searchTerm } = this.state;

        const filter_items = this.filterItems({
            items,
            searchTerm,
        });

        return (
            <div className="table-wrapper">
                {this.renderSearch({ 
                    searchTerm, 
                    placeholder,
                })}
                <table>
                    <thead>
                        {this.renderHeader()}
                    </thead>
                    <tbody>
                        {filter_items.map(d => this.renderRow(d))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;