import React, { Component } from 'react';
import Table from '../../../Layout/Table';

class ExclusionsTable extends Table {
    constructor(props) {
        super(props);
        this.deleteItems = this.deleteItems.bind(this);
    }

    deleteItems (ids = []) {
        let { updateComponent, componentData, items = componentData.items  } = this.props;
        const new_items = items.filter(t => !ids.includes(t.id));
        updateComponent({
            items: new_items,
        });
    }

    renderRow(data) {
        const {
            result,
            name,
            id,
            disabled,
        } = data;

        return (
            <tr key={id} className={disabled ? 'disabled' : ''}>
                <td>
                    <div className={`button ${ result ? 'error' : 'grey'} small`}>
                        { result ? 'TRUE' : 'FALSE' }
                    </div>
                </td>
                <td className="table-name">{ name }</td>
                <td>
                    <a className="button small grey icon" onClick={(e) => {
                        e.preventDefault();
                        this.deleteItems([ id ]);
                    }}>
                        <span className="cross"></span> Delete
                    </a>

                    <a className="button small secondary" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            highlightRow: id,
                        })
                        this.props.selectItem(data);
                    }}>
                        Edit
                    </a>
                </td>
            </tr>
        )
    }
}

export default ExclusionsTable;