import React, { Component } from 'react';
import { last } from 'lodash';

import {
    projectSchema,
    stepExclusionSchema,
    evaluateSchema,
} from '@swa_llow/pricing_engine';

import CommonEdit from './Edit/Common';
import Details from './Edit/Details';
import Inputs from './Edit/Inputs';

import { getProgressiveInput } from '../../utils/steps';

import ExclusionEdit from './Edit/Exclusions/ExclusionsEdit';

const mapSchema = (step) => {
    const map = {
        'exclusion' : stepExclusionSchema,
        'common': projectSchema,
    }
    return map[step] || map['common'];
}

const mapComponent = (step) => {
    const map = {
        'exclusion' : ExclusionEdit,
        'common': CommonEdit,
    }
    return map[step] || map['common'];
}

class EditArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {},
            inputs: {},
            errors: [],
        }
        this.updateInputValue = this.updateInputValue.bind(this);
        this.updateStep = this.updateStep.bind(this);
        this.validate = this.validate.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
    }

    componentWillMount() {
        const { selected:step, project } = this.props;
        const inputs = getProgressiveInput({ step_key:step.key, project });
        this.setState({
            step,
            inputs,
        });
    }

    updateInputValue(input) {
        this.setState({
            inputs: {
                ...this.state.inputs,
                ...input,
            }
        });
    }

    updateStep(data) {
        this.setState({
            step: {
                ...this.state.step,
                ...data
            }
        });
    }

    validate() {
        const { step } = this.state;

        this.setState({ errors: [] });

        const result = evaluateSchema({ 
            project: step,
            schema: mapSchema(step.step), 
        });

        let errors = (result.errors || []).map(e => {
            const key = last(e.path.split('.'));
            return {
                label: step.step,
                message: e.keyword,
                key,
            }
        });

        if (errors.length) {
            this.setState({ errors });
            return false;
        }

        return true;
    }

    async updateComponent() {
        const { step } = this.state;
        if(!this.validate()) return;
        await this.props.updateComponent({
            id: step.id,
            data: step,
        });
    }

    render() {
        const { 
            selected, 
        } = this.props;

        const { inputs, step, errors } = this.state;

        const EditComponent = mapComponent(selected.step);

        return (
            <section className="main-content slide-left">

                 <div className="design-button-area">
                    <div className="design-button-area-left">
                        
                        <a href="#" className="button grey big" onClick={(e) => {
                            e.preventDefault();
                            this.props.editComponent(null);
                        }}>&lt; Go Back To Designer</a>

                    </div>
                    <div className="design-button-area-right">

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            alert('Are you sure?');
                        }} className="button error big icon mr">
                            <span className="cross"></span>Delete Step
                        </a>

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.updateComponent();
                        }} className="button secondary big">
                            Save Step
                        </a>

                    </div>
                </div>

                <div className={`edit-area`}>
                    <div className="edit-area-left" style={{flex: 1}}>

                        <Details 
                            step={step}
                            errors={errors}
                            updateStep={this.updateStep}
                        />

                        <div className="edit-inputs">
                            <Inputs 
                                inputs={inputs}
                                updateInputValue={this.updateInputValue}
                            />
                        </div>

                    </div>

                    <div className="edit-area-center" style={{paddingRight: '0', flex: 2}}>
                        <div className="edit-area-canvas">
                            <div className="edit-form">
                                <EditComponent 
                                    errors={errors}
                                    step={step}
                                    inputs={inputs}
                                    updateStep={this.updateStep}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="edit-area-right" style={{display: 'none'}}></div>
                </div>
            </section>
        );
    }
}

export default EditArea;