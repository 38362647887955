import React from 'react';

function Table (props) {

    const {
        data = [],
        answers = {},
        update_answers = () => {},
    } = props;

    return (
        <div className="card awards">
            <table>
                <thead>
                    <tr>
                        <th>Years</th>
                        <th>Award</th>
                        <th>{ answers.awards && <button className="active show_number">-1</button>}</th>
                    </tr>
                </thead>
                <tbody>
                    { data.length === 0 && 
                        <tr>
                            <td className="no-data" colSpan={4}><b>No Awards</b></td>
                        </tr>
                    }

                    { !answers.awards && data.length > 0 &&
                        <tr className='strike'>
                            <td>
                                <b>-</b>
                            </td>
                            <td>
                                <b>-</b>
                            </td>
                            <td className="button">
                                <button className='' onClick={() => update_answers({
                                    type: 'awards',
                                    data: true,
                                })}>?</button>
                            </td>
                        </tr>
                    }

                    { answers.awards && data.map(n => {
                        return (
                            <tr>
                                <td>
                                    {(n.years || '').split(',').map((y = '') => {
                                        const year = y.replace(',','').trim();
                                        if (year === '') return;
                                        return [
                                            <span>{year}</span>, <br/>
                                        ];
                                    })}
                                </td>
                                <td>{n.comp}</td>
                                <td className="button"></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );

}

export default Table;
