import React, { Component } from 'react';
import Table from '../Layout/Table';

class TestTable extends Table {
    constructor(props) {
        super(props);
    }

    renderRow(data) {
        const {
            name,
            id,
            output: expected = {},
            result,
            disabled,
        } = data;

        const  { 
            selectedRows = [] 
        } = this.props;

        const is_selected = selectedRows.includes(id);

        return (
            <tr key={id} className={(disabled || !result) ? 'disabled' : ''}>
                <td>
                    <input checked={is_selected} type="checkbox" onChange={(e) => {
                        this.selectRow(id);
                    }}/>
                </td>
                <td className="table-name">{ name }</td>

                {result &&
                    <td>
                        <div className={`button ${ expected.result === result.result ? 'grey' : 'error'} small`}>{ expected.result }</div>
                        <div className={`button ${ expected.valid === result.valid ? 'grey' : 'error'} small`}>{ expected.valid ? 'VALID' : 'INVALID'}</div>
                    </td>
                }

                {result &&
                    <td>
                        <div className={`button ${ expected.result === result.result ? 'grey' : 'error'} small`}>{ result.result }</div>
                        <div className={`button ${ expected.valid === result.valid ? 'grey' : 'error'} small`}>{ result.valid ? 'VALID' : 'INVALID'}</div>
                    </td>
                }

                {!result &&
                    <td>
                        <div className="loading-ring secondary">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </td>
                }

                {!result &&
                    <td>
                        <div className="loading-ring secondary">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </td>
                }

                <td>
                    {/*!disabled &&
                        <a className="button disable small grey icon" onClick={(e) => {
                            e.preventDefault();
                            this.disableItems([ id ]);
                        }}>
                            <span className="cross"></span> Disable
                        </a>
                    */}

                    {/*disabled &&
                        <a className="button disable small secondary icon" onClick={(e) => {
                            e.preventDefault();
                            this.disableItems([ id ]);
                        }}>
                            <span className="cross"></span> Enable
                        </a>
                    */}
                    
                    <a className="button small error icon" onClick={(e) => {
                        e.preventDefault();
                        this.deleteItems([ id ]);
                    }}>
                        <span className="cross"></span> Delete
                    </a>

                    <a className="button small secondary icon" onClick={(e) => {
                        e.preventDefault();
                        this.props.runTest(data);
                    }}>
                        <span className="play"></span> Run
                    </a>

                    <a className="button small secondary" onClick={(e) => {
                        e.preventDefault();
                        this.props.viewTest(data);
                    }}>
                        Edit
                    </a>
                </td>
            </tr>
        )
    }
}

export default TestTable;