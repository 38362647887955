import React, { Component } from 'react';
import main from '../../../hoc/main';
import config from '../../../config';

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: null,
            success: null,
        }
        this.submit = this.submit.bind(this);
    }

    async componentDidMount() {
    }

    async submit (e) {
        e.preventDefault();
        const { email } = this.state;
        this.setState({
            isFetching: true,
            error: false,
        });
        if(validateEmail(email)) {
            try {
                await this.props.willow_landing_post({
                    data: {
                        email,
                    }
                });
                setTimeout(() => {
                    this.setState({
                        success: true,
                        isFetching: false,
                    });
                }, 750);
            } catch (e) {
                this.setState({
                    error: 'Issue submitting',
                    isFetching: false,
                })
            }
        } else {
            this.setState({
                error: 'Invalid email',
                isFetching: false,
            })
        }
    }

    render() {
        const { error, success, isFetching } = this.state;
        const { selected } = this.props.willow_landing;

        return [
            <div id="willow" className="splash-container">
                <div className="splash">
                    <div className="rect"></div>
                    <div className="left">
                        <h2>Willow</h2>
                        <h4>Get Hydrogen and Go!</h4>
                        <h6>Hydrogen Refuelling and Charging Network</h6>
                    </div>
                    <div className="right">
                        {!(success) &&
                            <form id="register">
                                <fieldset>
                                    <label>Register your interest</label>
                                    <input onKeyUp={(e) => {
                                        this.setState({ email: e.target.value})
                                    }} type="email" name="email" />
                                    <button onClick={this.submit}>
                                        {!isFetching && 'Submit'}
                                        {isFetching && 
                                            <div className="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        }
                                    </button>
                                </fieldset>
                                {!error && <p>We will not share your email with anyone. You can unsubscribe at any time.<br/><br/>Or you can email directly via <a href="mailto:wi@llow.io">wi@llow.io</a>.</p>}
                                {error && <p className="error">We have an error: <b>{error}</b></p>}
                            </form>
                        }

                        {success &&
                            <form id="register">
                                <fieldset>
                                    <label>Register your interest</label>
                                </fieldset>
                                <p className="success">You have successfully registered your email address with us.<br/><br/> You can <a href="#">unsubscribe</a> at any time.</p>
                            </form>
                        }
                        <p className="copyright-mobile">Llow Group &copy; 2022</p>
                    </div>
                    <div className="bubble x1"></div>
                    <div className="bubble x2"></div>
                    <div className="bubble x3"></div>
                    <div className="bubble x4"></div>
                    <div className="bubble x5"></div>
                    <div className="bubble x6"></div>
                    <div className="bubble x7"></div>
                </div>
                <p className="copyright">Llow Group &copy; 2022</p>
            </div>,
        ];
    }
}

export default main(Home, {
    is_private: false,
});