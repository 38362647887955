import { each, max, min, round, sumBy, first, sortBy, uniq, times } from 'lodash';

// This sanitises the data. It could be done at scraping stage.

function find_decades ({
    start, 
    end,
}) {
    let decades = [];
    const iterations = round((end - start) / 10, 0) + 1;
    times(iterations, (i) => {
        const year = start + (i * 10);
        if (year > end) return;   
        decades.push(round(year, -1).toString());
    });
    return decades;
}

function common_name (name = '') {
    let clean = decodeURI(name).replace(/_/ig, ' ');
    clean = clean.split('(')[0].trim();
    const words = clean.split(' ');
    return words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    }).join(' ');
}

const get_player_meta = (data = {}) => {
    each(data, (d, k) => {
        const { club, national, awards } = d;
        
        let years = [];
        const total_apps = sumBy(club, c => c.app);
        const total_goals = sumBy(club, c => c.goals);

        club.forEach(c => {
            const years_arr = c.years.split('–');
            const start_year = years_arr[0];
            const prefix = start_year.substr(0,2);
            let end_year;
            if (years_arr.length === 1) {
                end_year = start_year;
            } else {
                if (years_arr[1] === '–' || years_arr[1] === '') {
                    end_year = '2022';
                } else if (years_arr[1].length === 4) {
                    end_year = years_arr[1];
                } else {
                    end_year = prefix + years_arr[1];
                }
            }
            years = uniq([...years, round(start_year, 4), round(end_year, 4)]);
        });

        if (!d.personal) {
            delete data[k];
            return console.error('No personal data', k);
        }

        const full_name = d.personal.find(p => p.name === 'Full name');
        const positions = d.personal.find(p => p.name === 'Position(s)');
        
        if (!full_name || !positions) {
            delete data[k];
            return console.error('No position or name' , k);
        }

        d.tag_decades = uniq(find_decades({
            start : min(years),
            end : max(years),
        }));
        d.tags = d.tag_decades;

        d.tag_clubs = uniq(club.filter(c => c.app > 50).map(c => c.club));
        d.tags = [...d.tags, ...d.tag_clubs];

        if (national) {
            d.tag_national = uniq(national.filter(c => c.app > 40).map(c => c.club));
            d.tags = [...d.tags, ...d.tag_national];
        }

        if (awards) {
            d.tag_awards = [];

            const premier_league = awards.filter(a => a.comp === 'Premier League').length > 0;
            if (premier_league) d.tag_awards = ['Premier League'];
            
            const fa_cup = awards.filter(a => a.comp === 'FA Cup').length > 0;
            if (fa_cup) d.tag_awards = [...d.tag_awards, 'FA Cup'];

            const champions_league = awards.filter(a => a.comp === 'UEFA Champions League').length > 0;
            if (champions_league) d.tag_awards = [...d.tag_awards, 'Champions League'];

            const league_cup = awards.filter(a => a.comp === 'Football League Cup').length > 0;
            if (league_cup) d.tag_awards = [...d.tag_awards, 'League Cup'];

            const world_cup = awards.filter(a => a.comp === 'FIFA World Cup').length > 0;
            if (world_cup) d.tag_awards = [...d.tag_awards, 'World Cup'];

            d.tags = [...d.tags, ...d.tag_awards];
        }

        d = {
            ...d,
            slug: k,
            full_name: full_name.value,
            common_name: common_name(k),
            positions: positions.value,
            start_year : min(years),
            end_year : max(years),
            total_goals,
            total_apps,
        }
        
        if(!d.clubs) d.clubs = d.club;
        delete d.club;

        data[k] = d;
    });
    return data;
}

export default function (data) {
    const data_with_meta = get_player_meta(data);
    const slugs = Object.keys(data_with_meta);
    const response = {
        ids: slugs.map(s => {
            return {
                value: s,
                label: data_with_meta[s].common_name, 
            };
        }),
        data: data_with_meta,
    };

    return response;
}