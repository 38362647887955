import { unique_id } from '../utils';

const quote = {
    quote_reference: unique_id(),
    project_name: 'Motor Model',
    version: 1,
    project_reference: '12345678',
    version_reference: '12345678',
    result: '200.0000',
    valid: true,
    input: {
        postcode: 'PO343TR',
        reg: 'WO13BHZ',
        name: 'Rimmer',
        children: 0,
        email: 'cal@cal.com',
        age:  43,
        gender: 'M',
        smokes: false,
    },
    output: {
        result: '200.0000',
        valid: true,
        exclusions: [{
            name: 'Too young',
            value: true,
        }, {
            name: 'Fast Car',
            value: true,
        }],
        refers: [],
        endorsements: [],
        excesses: [{
            name: 'Too young',
            value: '100.00',
        }],
    },
    created_at: '2022-12-01T23:59:59Z',
};

export default quote;