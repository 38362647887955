import React, { Component } from 'react';

import BooleanSelect from './BooleanSelect';

import slugify from '../../../utils/slugify';
import { isUndefined } from 'lodash';

class EditDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const {
            step,
            errors = [],
            updateStep,
        } = this.props;

        const {
            name,
            key,
            def,
            description,
            retain,
        } = step;

        function has_error (key) {
            return errors.map(e => e.key).includes(key);
        }

        return (
            <div>
                <div className="edit-inputs">
                    <form>
                        <fieldset className={has_error('name') ? 'error spacing' : 'spacing'}>
                            <label>Step Name</label>
                            <input onChange={(e) => {
                                updateStep({
                                    name: e.target.value,
                                })
                            }} value={name} />
                        </fieldset>
                        <fieldset className={'spacing'}>
                            <label>Step Description</label>
                            <textarea 
                                value={description}
                                onChange={(e) => {
                                    updateStep({
                                        description: e.target.value,
                                    })
                                }}
                            />
                        </fieldset>
                    </form>
                </div>

                <div className="edit-inputs">
                    <form>
                        <fieldset className={has_error('name') ? 'error spacing' : 'spacing'}>
                            <label>Output Key</label>
                            <input onChange={(e) => {
                                updateStep({
                                    key: slugify(e.target.value),
                                })
                            }} value={key} />
                        </fieldset>

                        {!isUndefined(def) &&
                            <fieldset className={'spacing'}>
                                <label>Output Default Value</label>
                                <BooleanSelect 
                                    value={def}
                                    update={(value) => {
                                        updateStep({
                                            def: value,
                                        })
                                    }}
                                />
                            </fieldset>
                        }
                                
                        {!isUndefined(retain) &&
                            <fieldset className={'spacing'}>
                                <label>Retain Output</label>
                                <BooleanSelect 
                                    value={retain}
                                    update={(value) => {
                                        updateStep({
                                            BooleanSelect: value,
                                        })
                                    }}
                                />
                            </fieldset>
                        }

                    </form>
                </div>
            </div>
        );
    }
}

export default EditDetails;