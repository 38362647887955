import React, { Component } from 'react';
import { each, find } from 'lodash';

import Inputs from '../Design/Edit/Inputs';
import ActualResult from './ActualResult';

import {
    getStep,
    parseFormattedOutput,
} from '../../utils/steps';

import slugify from '../../utils/slugify';

class TestResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logs_open: false,
            inputs: {},
            outputs: {},
            name: '',
            description: '',
            key: null,
            is_change_since_run: false,
            is_running: false,
        }
        this.updateIsLogOpen = this.updateIsLogOpen.bind(this);
        this.refreshTest = this.refreshTest.bind(this);
        this.updateTest = this.updateTest.bind(this);
        this.updateInputs = this.updateInputs.bind(this);
        this.updateOutputs = this.updateOutputs.bind(this);
    }

    componentWillMount () {
        this.refreshTest();
    }

    refreshTest(){
        const { project, tests, selected_test_id } = this.props;
        const test = find(tests, t => t.id === selected_test_id);

        // Step formatter is expected a quote response with an input in steps
        // Tests don't do the input step
        // this returns { [key] : { value, type} }
        const defaultInputsformatted = getStep('input').output({ step: project.input });
        const inputsFormatted = getStep('input').output({ step: project.input, quote: {
            debug: {
                steps: {
                    ...test.debug.steps,
                    input: {
                        output: test.input,
                    }
                }
            }
        }});

        let inputs = {};
        each(inputsFormatted, (v, k) => {
            if (v.value) {
                inputs[k] = v;
            } else {
                inputs[k] = defaultInputsformatted[k];
            }
        });
        
        // Parameters we can edit
        this.setState({
            inputs,
            outputs: {
                result: {
                    type: 'decimal',
                    value: test.output.result,
                },
                valid: {
                    type: 'boolean',
                    value: test.output.valid,
                }
            },
            name: test.name,
            description: test.description,
            // This auto created by slugify from name
            key: test.key,
        });
    }

    updateIsLogOpen() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
        })
    }

    updateTest(data) {
        this.setState(data);
    }

    updateInputs(data) {
        this.setState({
            inputs: {
                ...this.state.inputs,
                ...data,
            },
            is_change_since_run: true,
        });
    }

    updateOutputs(data) {
        this.setState({
            outputs: {
                ...this.state.outputs,
                ...data,
            },
            is_change_since_run: true,
        });
    }

    render() {
        const {
            is_logs_open = false,
            is_change_since_run = false,
            is_running = false,
            inputs,
            outputs,
            name, 
            description,
            key,
        } = this.state;

        const {
            tests,
            selected_test_id,
        } = this.props;

        const test = find(tests, t => t.id === selected_test_id) || {};

        const {
            result,
            debug,
            id,
        } = test;

        const payload = {
            id: id,
            key: key,
            name,
            result: result,
            debug: debug,
            output: parseFormattedOutput(outputs),
            input:  parseFormattedOutput(inputs),
        }

        //TODO
        const has_error = () => false;

        return (
            <section key={id} className="main-content test-edit-area slide-left">
                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <a href="" className="button grey big" onClick={(e) => {
                            e.preventDefault();
                            this.props.viewTest(null);
                        }}>&lt; Go Back To Tests</a>
                    </div>
                    <div className="design-button-area-right">

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.deleteTest(id);
                        }} className="button error big icon mr">
                            <span className="cross"></span>Delete Test
                        </a>

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                outputs: {
                                    result: {
                                        value: result.result,
                                        type: outputs.result.type,
                                    },
                                    valid:  {
                                        value: result.valid,
                                        type: outputs.valid.type,
                                    }
                                }
                            });
                        }} className="button grey big mr">
                            Fix Test
                        </a>

                        <a href="#" onClick={async(e) => {
                            e.preventDefault();
                            this.setState({ is_running: true });
                            await this.props.runTest(payload);
                            this.setState({ is_running: false });
                        }} className="button secondary big mr">
                            Run Test
                        </a>

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.updateTests(payload);
                        }} className="button secondary big">
                            Save Test
                        </a>
                    </div>
                </div>
                <div className={`edit-area`}>
                    <div className="test-area-left" style={{ display: is_logs_open ? 'none' : 'block' }}>
                        <h3>Test Details </h3>
                        <p>You can explain what the test does.</p>
                        
                        <div className="edit-inputs">
                            <form>
                                <fieldset className={has_error('name') ? 'error spacing' : 'spacing'}>
                                    <label>Test Name</label>
                                    <input onChange={(e) => {
                                        this.updateTest({
                                            name: e.target.value,
                                            key: slugify(e.target.value),
                                        })
                                    }} value={name} />
                                </fieldset>

                                <fieldset className={'spacing'}>
                                    <label>Test Description</label>
                                    <textarea 
                                        value={description}
                                        onChange={(e) => {
                                            this.updateTest({
                                                description: e.target.value,
                                            })
                                        }}
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>

                    <div className="test-area-center" style={{ display: is_logs_open ? 'none' : 'block' }}>

                        <h3>Expected Output</h3>
                        <p>The keys and values for the test output are defined here. Only result and valid parameters can be supplied.</p>
                        <div className="edit-inputs">
                            <Inputs 
                                inputs={outputs}
                                updateInputValue={this.updateOutputs}
                            />
                        </div>

                        <div style={{height: '30px', width: '100%'}} />

                        <h3>Expected Input</h3>
                        <p>The parameters for the test are defined here. The format must match the initial input format steps.</p>
                        <div className="edit-inputs">
                            <Inputs 
                                inputs={inputs}
                                updateInputValue={this.updateInputs}
                            />
                        </div>

                    </div>

                    <div className="test-area-right">
                        <div className="edit-inputs"> 
                            <ActualResult 
                                quote={test}
                                is_running={is_running}
                                updateIsLogOpen={this.updateIsLogOpen}
                            />
                        </div>
                    </div>
                </div>

                
            </section>
        );
    }
}

export default TestResult;