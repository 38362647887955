import React, { Component } from 'react';
import main from '../../../../hoc/main';

import TopNav from '../../components/Layout/TopNav';

import project from '../../data/project';

class Projects extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.swallow_projects_get_all();
    }

    renderProjects (d = {}) {

        const is_live = d.published_at ? true : false;

        return (
            <div className="project">
                <div className="project-top">
                    <div className="project-top-left">
                        <h2><a href="">{ d.name }</a></h2>
                        <p>{ d.description }</p>
                    </div>
                    <div className="project-top-right">
                        <p className="label">Last 24 Hrs</p>
                        <div className="project-chart"></div>
                    </div>
                </div>
                <div className="project-bottom">
                    <div className="project-bottom-left top-nav">
                        <ul className="links-nav">
                            <li className="versions"><a href="">Versions</a></li>
                            <li className="team"><a href="">Team</a></li>
                            <li className="reviews"><a href="">Reviews</a></li>
                            <li className="data"><a href="">Data</a></li>
                            <li className="logs"><a href="">Logs</a></li>
                        </ul>
                    </div>
                    <div className="project-bottom-right">
                        { is_live && <span className="button live">Live</span> }
                        <a href="#" className="button">Try Latest</a>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const { data = [], isFetching } = this.props.swallow_projects;

        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} />

                <section className="main-content">

                    <div className="main-content-nav">
                        <div className="main-content-nav-left"></div>
                        <div className="main-content-nav-right">
                            <a href="" className="button big">+ Create New Project</a>
                        </div>
                    </div>

                    {!isFetching && data.length > 0 && 
                        data.map(d => this.renderProjects(d))
                    }

                </section>

            </div>
        );
    }
}

export default main(Projects, {
    is_private: true,
});