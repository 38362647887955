import React, { Component } from 'react';
import { steps } from '../../../utils/steps';
import slugify from '../../../utils/slugify';

class StepEmpty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step_name: null,
            step_type: null,

        }
    }

    render() {
        const {
            id,
            updateComponent,
        } = this.props;

        const {
            step_name,
            step_type,
        } = this.state;

        if (step_type) {
            return (
                <div className="empty-module">
                    <form>
                        <fieldset>
                            <input onKeyUp={(e) => {
                                this.setState({
                                    step_name: e.target.value,
                                })
                            }} type="text" />
                            <label>Enter Step Name</label>
                        </fieldset>
                        <button onClick={(e) => {
                            e.preventDefault();
                            const key = slugify(step_name);
                            updateComponent({
                                id,
                                data: {
                                    step: step_type,
                                    name: step_name,
                                    key,
                                    id,
                                }
                            });
                        }} className="button secondary small">+ Add</button>
                    </form>
                </div>
            );
        } else {
            return (
                <div className="empty-module">
                    <h4>Select Step</h4>
                    <ul>
                        {steps.filter(s => {
                            return !(s.step === 'input' || s.step === 'output');
                        }).map(t => {
                            return (
                                <li key={t.id} onClick={() => {
                                    this.setState({
                                        step_type: t.step,
                                    });
                                }}>
                                    <div className="module-type-item">
                                        <div className="module-type-tab">
                                        </div>
                                        <div className="module-type-icon">
                                            <span className="plus"></span>
                                        </div>
                                        <div className="module-type-label">
                                            <h4>{t.step}</h4>
                                            <p>{t.description}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
    }
}

export default StepEmpty;