import React, { Component } from 'react';

class CommonEdit extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default CommonEdit;