import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import {
    Router,
    Route,
} from 'react-router-dom';

import routes from './routes';
import store from './store';

import createHistory from 'history/createBrowserHistory';
const history = createHistory();

export const DefaultRender = (props) => (
    <div>
        <h3>This is a default page element</h3>
        <p>This probably means you haven't added a component in the routes</p>
    </div>
);

function flattenRoutes(routes = []) {
    const flatLinks = [];
    routes.forEach(link => {
        const { sublinks = [], path, component } = link
        if (sublinks.length === 0) {
            flatLinks.push({
                path,
                component,
            });
        } else {
            sublinks.forEach(s => {
                flatLinks.push({
                    path: s.path,
                    component: s.component,
                });
            })
        }
    });
    return flatLinks;
}

ReactDOM.render(
    <Provider store={store()}>
        <Router history={history}>
            {flattenRoutes(routes).map(r => {
                let { component } = r;
                if (!component) component = DefaultRender;
                return (
                    <Route exact path={r.path} component={component} />
                );
            })}
        </Router>
    </Provider>,
    document.getElementById('root'),
);