import React, { Component } from 'react';
import { WFInput } from '../../components/wireframes/modules';

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.update = this.update.bind(this);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const { email } = this.state;
        const { lang, isFetching } = this.props;

        if(isFetching) {
            return (
                    <div className="wf wf-br">
        
                        <h2>Sending </h2>
                        <p>Please check your email client now...</p>
        
                        <div className="wf-loader">
                            <div className="loading-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
        
                    </div>
            );
        } else {
            return (
                <div id="start" className="wf">
    
                    <h2>Enter Your Email Address</h2>

                    <WFInput
                        name={'email'}
                        label={''}
                        submit={(value) => { this.update(value) }}
                        placeholder={'Email Address'}
                        validation={(value) => {
                            if (!value.includes('@')) return false;
                            if (!value.includes('.')) return false;
                            return value.length < 100;
                        }}
                    />
    
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.props.create_auth_session({
                            email,
                            type: 'START'
                        });
                    }}>
                        Login
                    </button>

                    <p>Don't have an account? <a href="#">Register here</a></p>

                </div>
            );
        }

        
    }
}
export default Start;