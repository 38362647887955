import React, { Component } from 'react';

import { WFInput, WFSelect, WFCode } from './modules';

class Wireframes extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div>
                <header>
                    <nav>
                        <a href="#elements">Elements</a>
                        <a href="#layouts">Layouts</a>
                    </nav>

                    <h1>Header Area</h1>
                    <p>Tagline to header area</p>

                </header>

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <h1><code>Elements</code></h1>
                        </div>
                    </div>
                </div>

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <h2>Basic Typography</h2>

                            <h1>Heading 1</h1>

                            <h2>Heading 2</h2>

                            <h3>Heading 3</h3>

                            <h4>Heading 4</h4>

                            <h5>Heading 5</h5>

                            <h6>Heading 6</h6>

                            <h3>Links &amp; Buttons</h3>

                            <p><a href="https://example.com">I’m a hyperlink</a></p>

                            <p>
                                <button>I’m a button</button>
                            </p>

                            <p>
                                <button className="wf-btn-sm">I’m a small button</button>
                            </p>

                            <p>
                                <a href="https://example.com">
                                    <button>I’m a button with a link</button>
                                </a>
                            </p>

                            <h2>Other typography elements</h2>

                            <ul>
                                <li>
                                    All the standard stuff, like <strong>bold</strong>, <em>italic</em> and <u>underlined</u> text.
                                </li>
                                <li>
                                    <mark>Highlighting text</mark> using the element.
                                </li>
                                <li>
                                    Adding using the element</li>
                                <li>
                                    Displaying keyboard commands like <kbd>ALT+F4</kbd> using the element.
                                </li>
                            </ul>

                            <h3 id="lists">Lists</h3>

                            <ul>
                                <li>Item 1</li>
                                <li>Item 2</li>
                                <li>Item 3</li>
                            </ul>

                            <ol>
                                <li>Do this thing</li>
                                <li>Do that thing</li>
                                <li>Do the other thing</li>
                            </ol>

                            <h3>Code blocks</h3>

                            <WFCode 
                                code={{ 
                                    string : 'string',
                                    number : 3,
                                    boolean : false,
                                    date: '2012-12-12',
                                }}    
                            />

                            <h2>Other HTML elements</h2>

                            <h3>Article</h3>

                            <article>
                                <h2>This is an article</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </article>

                            <h3>Section</h3>

                            <section>
                                <h2>This is a section</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </section>

                            <h2>Navigation</h2>

                            <nav>
                                <a href="">Home</a>
                                <a href="">About</a>
                                <a href="">Blog</a>
                                <a href="">Notes</a>
                                <a href="">Contact</a>
                            </nav>

                            <h2>Images</h2>

                            <p>
                                <img src="https://simplecss.org/assets/images/dog-ipad.jpg" alt="A dog at an iPad" />
                            </p>

                            <figure>
                                <img alt="This is a black swan" src="https://simplecss.org/assets/images/goose.jpg" />
                                <figcaption>This is a black swan</figcaption>
                            </figure>

                            <h2 id="accordions">Accordions</h2>

                            <details>
                                <summary>Spoiler alert!</summary>
                                <p>Content - No JS needed</p>
                            </details>

                            <h2 id="tables">Tables</h2>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Jackie</td>
                                        <td>012345</td>
                                    </tr>
                                    <tr>
                                        <td>Lucy</td>
                                        <td>112346</td>
                                    </tr>
                                    <tr>
                                        <td>David</td>
                                        <td>493029</td>
                                    </tr>
                                    <tr>
                                        <td>Kerry</td>
                                        <td>395499</td>
                                    </tr>
                                    <tr>
                                        <td>Steve</td>
                                        <td>002458</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Forms</h2>

                            <form>
                                <fieldset>
                                    <select autoComplete="off">
                                        <option selected="selected" value="1">Title</option>
                                        <option value="2">Mr</option>
                                        <option value="3">Miss</option>
                                        <option value="4">Mrs</option>
                                        <option value="5">Other</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <label>First name</label>
                                    <input type="text" name="first_name" autoComplete="off" />
                                </fieldset>
                                <fieldset>
                                    <label>Surname</label>
                                    <input type="text" name="surname" autoComplete="off" />
                                </fieldset>
                                <fieldset>
                                    <label>Email</label>
                                    <input type="email" name="email" required="" autoComplete="off" />
                                </fieldset>
                                <fieldset>
                                    <label>Enquiry type:</label>
                                    <label>
                                        <input checked="checked" name="type" type="radio" value="sales" /> Sales
                                    </label> 
                                    <label>
                                        <input name="type" type="radio" value="support" /> Support
                                    </label> 
                                    <label>
                                        <input name="type" type="radio" value="billing" /> Billing
                                    </label>
                                </fieldset>
                                <fieldset>
                                    <label>Message</label>
                                    <textarea autoComplete="off" rows="6"></textarea>
                                </fieldset>
                                <fieldset>
                                    <label>Choose a car:</label>
                                    <select name="cars" id="cars" multiple="" autoComplete="off">
                                        <option value="volvo">Volvo</option>
                                        <option value="saab">Saab</option>
                                        <option value="opel">Opel</option>
                                        <option value="audi">Audi</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <label>
                                        <input type="checkbox" id="checkbox" value="terms" autoComplete="off" />
                                        I agree to the <a href="#">terms and conditions</a>
                                    </label>
                                </fieldset>

                                <button>Send</button>
                                <button type="reset">Reset</button>
                                <button disabled="disabled">Disabled</button>
                            </form>

                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <h1 id="layouts"><code>Layouts</code></h1>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-4">
                            <div className="wf wf-bg">
                                <ul>
                                    <li><a href="">Home</a></li>
                                    <li><a href="" className="current">About</a></li>
                                    <li><a href="">News</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="wf-col-4">
                            <div className="wf wf-br">
                                <h3>Article</h3>
                                <article>
                                    <h2>This is an article</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                </article>
                            </div>
                        </div>
                        <div className="wf-col-4">
                            <div className="wf">
                                <h3>Forms</h3>
                                <form>
                                    <WFSelect 
                                        name={'title'}
                                        label={'Title'}
                                        items={[
                                            {
                                                name: 'Mr',
                                                value: 1,
                                            },
                                            {
                                                name: 'Miss',
                                                value: 2,
                                            },
                                            {
                                                name: 'Mrs',
                                                value: 3,
                                            },
                                            {
                                                name: 'Other',
                                                value: 4,
                                            }
                                        ]}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                    <WFInput 
                                        name={'first_names'}
                                        label={'First names'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                    <WFInput 
                                        name={'surname'}
                                        label={'Surname'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-4">
                            <div className="wf wf-br">
                                <h4>Pool Name</h4>
                                <p>Pool description details here</p>
                                <p>ID: 234567</p>
                                <button>View</button>
                            </div>
                        </div>
                        <div className="wf-col-4">
                            <div className="wf wf-bg">
                                <h4>Pool Name</h4>
                                <p>Pool description details here</p>
                                <p>ID: 234567</p>
                                <button>View</button>
                            </div>
                        </div>
                        <div className="wf-col-4">
                            <div className="wf wf-br">
                                <h4>Pool Name</h4>
                                <p>Pool description details here</p>
                                <p>ID: 234567</p>
                                <button>View</button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th className="wf-btn-cell">
                                            <button className="wf-btn-sm">Download</button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Jackie</td>
                                        <td>012345</td>
                                        <td className="wf-btn-cell">
                                            <button className="wf-btn-sm">View</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Lucy</td>
                                        <td>112346</td>
                                        <td className="wf-btn-cell">
                                            <button className="wf-btn-sm">View</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>David</td>
                                        <td>493029</td>
                                        <td className="wf-btn-cell">
                                            <button className="wf-btn-sm">View</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <footer>
                    <p>Wireframing CSS.</p>
                </footer>

            </div>
        );
    }
}

export default Wireframes;