import React, { Component } from 'react';
import { unique_id } from '../../../../utils';
import ExclusionsTable from './ExclusionsTable';
import ExpressionTextarea from '../ExpressionTextarea';
import BooleanSelect from '../BooleanSelect';

import {
    QL,
    evaluateExpression,
    stepExclusionSchema,
    evaluateSchema,
} from '@swa_llow/pricing_engine';

import { each, isUndefined, last } from 'lodash';

function evaluateExpressions({
    inputs,
    items,
}) {
    const quote = {};

    each(inputs, (v, k) => {
        quote[k] = v.value;
    });

    const result = items.map(i => {
        const { exp } = i;
        const result = evaluateExpression({ expression: exp }, QL(quote));
        return {
            ...i,
            result: result.result,
            elements: result.elements,
        };
    });

    return result;
}

class ExclusionsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            test: null,
            errors: [],
        }
        this.updateExclusion = this.updateExclusion.bind(this);
        this.selectExclusion = this.selectExclusion.bind(this);
        this.validate = this.validate.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.clearExclusion = this.clearExclusion.bind(this);
        this.testExpression = this.testExpression.bind(this);
    }

    updateExclusion (data) {
        const { selected } = this.state;

        const new_data = {
            ...selected,
            ...data,
        }

        this.setState({
            errors: [],
            selected: new_data
        });
    }

    clearExclusion () {
        this.setState({
            errors: [],
            selected: null,
            test: null,
        });
    }

    testExpression () {
        const { inputs } = this.props;
        const { selected = {} } = this.state;
        if (!selected.exp) return;
        const result = evaluateExpressions({
            inputs,
            items: [selected]
        }) || [];
        this.setState({
            test: result[0],
        })
    }

    selectExclusion (selected) {
        this.setState({
            errors: [],
            selected,
            test: null,
        })
    }

    validate({ new_items }) {
        const { step } = this.props;

        const result = evaluateSchema({ 
            project: {
                ...step,
                items: new_items,
        }, schema: stepExclusionSchema  });

        let errors = (result.errors || []).map(e => {
            const key = last(e.path.split('.'));
            return {
                label: 'Exclusion',
                message: e.keyword,
                key,
            }
        });

        if (errors.length) {
            this.setState({ errors });
            return false;
        }

        return true;
    }

    async updateComponent() {
        
        const { step, updateStep } = this.props;
        const { items } = step;
        const { id = '' } = this.state.selected || {};

        let new_items = items;

        if (id === '') {
            new_items = [...items, {
                ...this.state.selected,
                id: unique_id(),
            }];
        } else {
            new_items = items.map(item => {
                if (item.id === id) {
                    return this.state.selected;
                }
                return item;
            });
        }

        if(!this.validate({ new_items })) return;

        await updateStep({
            items: new_items,
        });

        this.clearExclusion();
    }

    render() {
        const { 
            step,
            inputs,
        } = this.props;

        const { selected , errors = [], test = {} } = this.state;

        let {
            id = '',
            name = '',
            exp = '',
            def = '',
        } = selected || {};

        function has_error (key) {
            return errors.map(e => e.key).includes(key);
        }

        return [
            <div className="exclusions-edit">
                <div className="table-control-panel">
                    <div className="table-control-panel-left"></div>
                    <div className="table-control-panel-right">
                        <a href="#" className="button grey small mr"
                            onClick={this.clearExclusion}
                        > Clear Exclusion</a>
                        <a href="#" className="button secondary small" 
                            onClick={this.updateComponent}
                        > {id === '' ? 'Create' : 'Update'} Exclusion</a>
                    </div>
                </div>

                { errors.length > 0 &&
                    <div className="edit-errors">
                        <p>{errors.map((e, i) => {
                            return [<span> {i > 0 ? 'and' : ''} </span> , <b> {e.label}</b>, <em> {e.key}</em>, <em> {e.message}</em>];
                        })}</p>
                    </div>
                }

                <div className="edit-columns">
                    <div className="edit-column">
                        <form>
                            <fieldset className={has_error('name') ? 'error spacing' : 'spacing'}>
                                <label>Exclusion Name</label>
                                <input onChange={(e) => {
                                    this.updateExclusion({
                                        name: e.target.value,
                                    })
                                }} value={name} />
                            </fieldset>
                            <fieldset className={has_error('def') ? 'error spacing' : 'spacing'}>
                                <label>Default to</label>
                                <BooleanSelect
                                    value={def}
                                    update={(data) => {
                                        this.updateExclusion({
                                            def: data.value,
                                        });
                                    }}
                                />
                            </fieldset>
                            <fieldset className={has_error('exp') ? 'error spacing' : 'spacing'}>
                                <label>Expression</label>
                                <ExpressionTextarea 
                                    inputs={inputs} 
                                    value={exp}
                                    updateExpression={(value) => {
                                        this.updateExclusion({
                                            exp: value,
                                        });
                                    }}
                                />
                            </fieldset>
                        </form>
                    </div>
                </div>
                {exp !== '' &&
                    <div className="table-control-panel bottom">
                        <div className="table-control-panel-left">
                            {test && !isUndefined(test.result) &&
                                <div className={`button ${ test.result ? 'error' : 'grey'} small`}>
                                    { test.result ? 'TRUE' : 'FALSE' }
                                </div>
                            }
                        </div>
                        <div className="table-control-panel-right">
                            <a href="#" className="button secondary small"
                                onClick={this.testExpression}
                            > Test Expression</a>
                        </div>
                    </div>
                }
            </div> 
            ,<div className="exclusions-table">
                <ExclusionsTable 
                    inputs={inputs}
                    headers={[
                        'result',
                        'name',
                    ]}
                    hide_checkboxes={true}
                    componentData={{
                        ...step,
                        items: evaluateExpressions({
                            inputs, 
                            items: step.items,
                        }),
                    }}
                    updateComponent={this.props.updateStep}
                    selectItem={this.selectExclusion}
                />
            </div>
        ];
    }
}

export default ExclusionsEdit;