import React from 'react';
import { round, sortBy } from 'lodash';

function Table (props) {
    
    const {
        data = [],
        answers = {},
        update_answers = () => {},
    } = props;

    const strike_check = (cur_club) => {
        const needle = answers.clubs.filter(c => {
            return c.club === cur_club.club && c.years === cur_club.years;
        });
        return needle.length > 0;
    }

    return (
        <div className="card clubs">
            <table>
                <thead>
                    <tr>
                        <th>Years</th>
                        <th>Club</th>
                        <th>Apps</th>
                        <th>Goals</th>
                    </tr>
                </thead>
                <tbody>
                    
                    { data.length === 0 && 
                        <tr>
                            <td className="no-data" colSpan={4}><b>No Clubs</b></td>
                        </tr>
                    }

                    { data.length > 0 && data.map((c, i) => {
                        return (
                            <tr key={`${i}-clubs-${c.years}`} className={strike_check(c) ? '' : 'strike'}>
                                <td>
                                    {strike_check(c) ?
                                        <span>{c.years}</span> :
                                        <b>-</b>
                                    }
                                </td>
                                <td>
                                    <b>{strike_check(c) ? c.club : '-'}</b>
                                </td>
                                <td>
                                    <b>{strike_check(c) ? c.app : '-'}</b>
                                </td>
                                <td>
                                    <b>{strike_check(c) ? c.goals : '-'}</b>
                                </td>
                                <td className="button">
                                    <button className={strike_check(c) ? 'active' : ''} onClick={() => {
                                        if (strike_check(c)) return;
                                        update_answers({
                                            type: 'clubs',
                                            data: c,
                                        })}
                                    }>{strike_check(c) ? '-1' : '?'}</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Table;