import { unique_id } from '../utils';

const quotes = [{
    quote_reference: unique_id(),
    project_name: 'Motor Model',
    version: 1,
    project_reference: '12345678',
    version_reference: '12345678',
    result: '200.0000',
    valid: true,
    indicator_1: 'cal@cal.com',
    indicator_2: 'PO343RT',
    indicator_3: 'WO13BHZ',
    indicator_4: 'Rimmer',
    created_at: '2022-12-01T23:59:59Z',
},{
    quote_reference: unique_id(),
    project_name: 'Motor Model',
    version: 1,
    project_reference: '12345678',
    version_reference: '12345678',
    result: '340.0000',
    valid: false,
    indicator_1: 'cal@bob.com',
    indicator_2: 'WO343RT',
    indicator_3: 'XO13BHZ',
    indicator_4: 'Wimmer',
    created_at: '2022-12-01T23:58:59Z',
}];

export default quotes;