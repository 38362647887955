import React from 'react';
import { useInView } from 'react-intersection-observer';

import video from '../assets/landing/hero-720.mp4';
import montagetests from '../assets/landing/montage-tests.gif';
import montageexcel from '../assets/landing/montage-excel.gif';

const Video = () => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        triggerOnce: true,
        threshold: 0.75,
    });

    return [
        <section ref={ref} className="montage">
            <div className={`montage-excel ${inView ? 'slide' : ''}`}>
                <img src={montageexcel} />
            </div>
            <div className="main-video">
                
                <div className="video-embed">
                    <video
                        id="video"
                        defaultMuted
                        muted="muted"
                        autoPlay 
                        autoplay="true"
                        loop="loop"
                        playsinline
                        preload="auto"
                        style={{ width: '100%', maxHeight: '100%' }}
                    >
                        <source type="video/mp4" src={video} />
                    </video>
                </div>
            </div>
            <div className={`montage-tests ${inView ? 'slide' : ''}`}>
                <img src={montagetests} />
            </div>
        </section>
    ];
};

export default Video;