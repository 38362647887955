import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang } = this.props;
        return (
            <footer>
                <p>A.llow is a Llow Group Company.</p>
            </footer>
        );
    }
}

export default Footer;
