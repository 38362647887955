import React, { Component } from 'react';
import { first, isUndefined } from 'lodash';

import main from '../../../../hoc/main';

import TopNav from '../../components/Layout/TopNav';
import DesignArea from '../../components/Design/DesignArea';
import EditArea from '../../components/Design/EditArea';

import { unique_id } from '../../utils';
import { getId } from '../../../../utils/url';

class VersionDesign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [],
            selected_component: null,
        }
        this.addComponent = this.addComponent.bind(this);
        this.editComponent = this.editComponent.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.refreshVersion = this.refreshVersion.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
    }

    async componentDidMount() {
        const { selected:version } = this.props.swallow_versions;
        await this.refreshVersion(version);
    }

    async refreshVersion(version) {
        const id = getId(this.props.history);
        if (!version) {
            await this.props.swallow_versions_get({
                id, 
            });
        }
        const { steps } = this.props.swallow_versions.selected;
        this.setState({
            steps, 
        });
    }

    async saveVersion() {
        const id = getId(this.props.history);
        const { selected:version } = this.props.swallow_versions;
        const { steps } = this.state;
        await this.props.swallow_versions_put({
            id, 
            data: {
                project: {
                    ...version,
                    steps,
                    // TODO input, output
                }
            }
        });
    }

    addComponent(index = 0) {
        const { steps } = this.state;
        const new_steps = [
            ...steps.slice(0, index),
            {
                step: null,
                name: null,
                key: null,
                id: unique_id(),
            },
            ...steps.slice(index)
        ];
        this.setState({ steps: new_steps });
    }

    editComponent(id) {
        if (!id) return this.setState({
            selected_component: null,
        });
        const { steps } = this.state;
        this.setState({
            selected_component: first(steps.filter(s => s.id === id)), 
        });
    }

    updateComponent({ id, data }) {
        const { steps } = this.state;
        const new_steps = steps.map(step => {
            if (step.id === id) {
                return data;
            }
            return step;
        });
        this.setState({
            steps: new_steps,
            selected_component: null
        }, () => {
            this.saveVersion();
        });
    }

    render() {
        const { selected_component, steps } = this.state;
        const { isFetching = true, selected:version = {} } = this.props.swallow_versions;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} />

                { !isFetching && !selected_component &&
                    <DesignArea 
                        steps={steps}
                        project={version}
                        selected={selected_component}
                        addComponent={this.addComponent}
                        updateComponent={this.updateComponent}
                        editComponent={this.editComponent}
                        saveVersion={this.saveVersion}
                    />
                }

                { !isFetching && selected_component &&
                    <EditArea 
                        project={version}
                        selected={selected_component}
                        updateComponent={this.updateComponent}
                        editComponent={this.editComponent}
                    /> 
                }

            </div>
        );
    }
}

export default main(VersionDesign, {
    is_private: true,
});