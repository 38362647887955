import React from 'react';

const Features = () => {
    return [
        <section className="features">
            <div className={`header-block`}>
                <h3>Build pricing models like software</h3>
                <p>The process of building a pricing model is the same as software development. The software development lifecycle is a tried and tested way of quickly delivering whilst maintaining quality.</p>
            </div>
            <div className="feature-blocks">
                <div className="row">
                    <div className="cell">
                        <h4><span>1</span> Design</h4>
                        <p><b>How it is:</b> Design is usually done on a shared spreadsheet that is email between stakeholders.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow uses a shared drag'n'drop design board so that all stakeholders can collaborate together in one place.</p> 
                        </div> 
                    </div>
                    <div className="cell">
                        <h4><span>2</span> Build</h4>
                        <p><b>How it is:</b> The pricing model spreadsheet needs to be converted in to code by a developer.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow uses a low code solution to allow any stakeholder to build a pricing model.</p> 
                        </div> 
                    </div>
                    <div className="cell">
                        <h4><span>3</span>Test</h4>
                        <p><b>How it is:</b> A new spreadsheet with test scenarios is created and shared with the build developer.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow has a built-in testing suite that means you can automatically run hundreds of test on any change.</p> 
                        </div>
                    </div>
                    <div className="cell">
                        <h4><span>4</span>Review</h4>
                        <p><b>How it is:</b> The developer emails asking for permission to package the new model for publishing.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow has an audited version control system that enforces reviews and approval from stakeholders before packaging and publication.</p> 
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell">
                        <h4><span>5</span> Package</h4>
                        <p><b>How it is:</b> The spreadsheet is now packaged to code. It is de-coupled from stakeholders.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow either packages to a beautifully formatted Excel spreadsheet or directly to Swallow's publishing system.</p> 
                        </div>
                    </div>
                    <div className="cell">
                        <h4><span>6</span> Publish</h4>
                        <p><b>How it is:</b> The developer will be the gatekeeper to publishing the new pricing model to your customers.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow uses a serverless, cloud architecture that means any stakeholder can deploy (or revert) globally with a click of a button.</p> 
                        </div>
                    </div>
                    <div className="cell">
                        <h4><span>7</span>Data</h4>
                        <p><b>How it is:</b> The developer will be the gatekeeper to accessing data that the published pricing model produces.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow gives stakeholders real-time access to all collected data via a dashboard or direct access to a curated data lake.</p> 
                        </div>
                    </div>
                    <div className="cell">
                        <h4><span>8</span>Iterate</h4>
                        <p><b>How it is:</b> To evolve the pricing model, a new spreadsheet is created from the old spreadsheet.</p>
                        <div className="swallow-feature">
                            <div className="logo"></div>
                            <p><b>What we do:</b> Swallow stores all previous pricing model versions with their data and audit trial in one central hub.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ];
};

export default Features;