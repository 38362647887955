import React, { Component } from 'react';
import main from '../../../hoc/main';

import { 
    WFInput, 
    WFTextArea,
    WFSelect
} from '../../../components/wireframes/modules';

import { getId } from '../../../utils/url';
import { isUndefined } from 'lodash';

import Header from '../components/Header';
import Footer from '../components/Footer';

class SessionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            session_expired: false,
        }
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        this.setState({ id }, async() => {
            try {
                const result = await this.props.allow_sessions_get({ id });
            } catch (e) {
                this.setState({ session_expired : true });
            }
        });
    }

    render() {
        const { isFetching, selected: session , error  } = this.props.allow_sessions;
        const { session_expired } = this.state;

        if (session_expired) {
            return (
                <div>
                    <Header />
                    <hr />
                    <div className="wf-container">
                        <div className="wf-row">
                            <div className="wf-col-12">
                                <div className='wf wf-bg'>
                                    <p><b>Session has expired</b></p>
                                    <button onClick={() => this.props.history.goBack()}>Go Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
        return (
            <div>
                <Header />

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-6">
                            {!isFetching && session &&
                                <div className="wf wf-br">
                                    <p><b>Session</b> <br/> {session.session}</p>
                                </div>
                            }

                            <hr />

                            {!isFetching && session &&
                                <div className="wf wf-br">
                                    <p><b>Access Token</b> <br/> {session.access_token}</p>
                                </div>
                            }

                            <hr />

                            {!isFetching && session &&
                                <div className="wf wf-br">
                                    <p><b>Refresh Token</b> <br/> {session.refresh_token}</p>
                                </div>
                            }

                            {isFetching &&
                                <div className="wf wf-br">
                                    <div className='wf-loader'></div>
                                </div>
                            }


                        </div>
                        <div className="wf-col-6">
                            {!isFetching && session &&
                                <div className="wf wf-br">
                                    <p><b>Email</b> <br/> {session.email}</p>
                                    <p><b>User Reference</b> <br/> {session.user_reference}</p>
                                    <p><b>Pool Reference</b> <br/> {session.pool_reference}</p>
                                    <p><b>Created At</b> <br/> {session.created_at}</p>
                                </div>
                            }

                            {isFetching &&
                                <div className="wf wf-br">
                                    <div className='wf-loader'></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <Footer />


            </div>
        );
    }
}

export default main(SessionDetail, {
    is_private: true,
});