import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import rootReducer from './reducers';
import { get_auth } from './auth';
 
const client = axios.create({
    responseType: 'json',
});

// This uses allow_sessions
const authentication = (store) => (next) => async (action) => {
    const data = store.getState();

    //This will always get the latest access_token from refresh_token if needed.
    const { access_token: token } = await get_auth();

    // Add all authorization to requests
    if (action && action.payload) {
        return next({
            ...action,
            payload: {
                ...action.payload,
                request: {
                    ...action.payload.request,
                    headers: {
                        ...action.payload.request.headers,
                        authorization: token ? `Bearer ${token}` : '',
                    }
                }
            }
        })
    };

    return next(action);
};

const middleware = applyMiddleware(
    authentication,
    axiosMiddleware(client, { returnRejectedPromiseOnError: true }),
    thunkMiddleware,
);

const initStore = (initialState = {}) => createStore(rootReducer, initialState, middleware);

export default initStore;