import React, { Component } from 'react';

import PositionsTable from '../position/Table';
import AwardsTable from '../awards/Table';
import NationalTable from '../international/Table';

class BonusSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bonus: null,
        }
        this.update_bonus = this.update_bonus.bind(this);
    }

    update_bonus(bonus) {
        this.setState({
            bonus,
        });
    }

    render () {
        const {
            data = {},
            update_answers = () => {},
        } = this.props;
    
        const { bonus } = this.state;

        const { national, awards, personal } = data;
    
        const options = [{
            label: 'Player Positions',
            key: 'positions',
        },{
            label: 'National Caps',
            key: 'international',
        },{
            label: 'Player Awards',
            key: 'awards',
        }];
    
        return (
            <div>
                {!bonus &&
                    <div className="card bonus-select">
                        <button onClick={() => this.update_bonus('positions')} className="position">Player Position</button>
                        <button onClick={() => this.update_bonus('awards')} className="awards">Player Awards</button>
                        <button onClick={() => this.update_bonus('international')} className="international">National Caps</button>
                    </div>
                }
                {bonus === 'positions' && <PositionsTable data={personal} />}
                {bonus === 'awards' && <AwardsTable data={awards} />}
                {bonus === 'international' && <NationalTable data={national} />}

                {bonus === 'positions' && <button onClick={() => update_answers({
                    type: 'positions',
                    data: true,
                })}>Next</button>}

                {bonus === 'awards' && <button onClick={() => update_answers({
                    type: 'awards',
                    data: true,
                })}>Next</button>}

                {bonus === 'international' && <button onClick={() => update_answers({
                    type: 'international',
                    data: true,
                })}>Next</button>}

            </div>
        );
    }
}

export default BonusSelect;