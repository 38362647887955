import React, { Component } from 'react';
import main from '../../../hoc/main';

import { 
    WFInput, 
    WFTextArea,
    WFSelect
} from '../../../components/wireframes/modules';

import { getId } from '../../../utils/url';
import { isUndefined } from 'lodash';

import Header from '../components/Header';
import Footer from '../components/Footer';

import SessionsTable from '../components/SessionsTable';

class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            new_email: null,
            new_custom: null,
            new_is_enabled: null,
        }
        this.regenerate_keys = this.regenerate_keys.bind(this);
        this.save_user = this.save_user.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        this.setState({ id }, async() => {
            const users = await this.props.allow_users_get({ id });
            const sessions = await this.props.allow_sessions_get_all({ user_reference: id });
        });
    }

    save_user () {
        const { 
            id,
            new_custom,
            new_is_enabled
        } = this.state;

        let payload = {};

        if (new_custom) payload = {...payload, custom: new_custom};
        if (!isUndefined(new_is_enabled)) payload = {...payload, is_enabled: new_is_enabled};

        this.props.allow_users_put({
            id,
            data: payload,
        });
    }

    regenerate_keys () {
        const { 
            id,
        } = this.state;
        this.props.allow_users_put({
            id,
            data: {
                refresh_keys: true,
            },
        });
    }

    render() {
        const { isFetching, selected: user = {} , error  } = this.props.allow_users;
        const { isFetching: isFetchingSessions, data: sessions = [] } = this.props.allow_sessions;
        
        return (
            <div>
                <Header />

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <div className="wf wf-bg">
                                <button>Delete User</button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-6">
                            {!isFetching && user &&
                                <div className="wf wf-br">

                                    <p><b>Email</b> <br/> {user.email}</p>
                                    <p><b>User Reference</b> <br/> {user.user_reference}</p>
                                    <p><b>Pool Reference</b> <br/> {user.pool_reference}</p>
                                    <p><b>Last Login</b> <br/> {user.last_token_check_at}</p>
                                    <p><b>Last Verify</b> <br/> {user.last_verify_check_at}</p>
                                    <p><b>Created At</b> <br/> {user.created_at}</p>

                                    <hr />

                                    <p><b>Client Id</b> <br/> {user.client_id_key}</p>
                                    <p><b>Client Secret</b> <br/> {user.client_secret_key}</p>
                                    <button onClick={this.regenerate_keys}>Regenerate Keys</button>

                                </div>
                            }

                            {isFetching &&
                                <div className="wf wf-br">
                                    <div className='wf-loader'></div>
                                </div>
                            }


                        </div>
                        <div className="wf-col-6">
                            {!isFetching && user &&
                                <div className="wf wf-br">
                                    <WFSelect 
                                        name={'is_enabled'}
                                        items={[
                                            {
                                                name: 'true',
                                                value: 'true'
                                            },
                                            {
                                                name: 'false',
                                                value: 'false'
                                            }
                                        ]}
                                        value={user.is_enabled}
                                        label={'User Enabled'}
                                        submit={(value)=> { this.setState({
                                            new_is_enabled: value.is_enabled,
                                        })}}
                                    />
                                    <WFTextArea 
                                        name={'custom'}
                                        value={user.custom}
                                        label={'User Custom Data'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { this.setState({
                                            new_custom: value.custom,
                                        })}}
                                    />
                                    <button onClick={this.save_user}>Save User</button>
                                </div>
                            }
                            {isFetching &&
                                <div className="wf wf-br">
                                    <div className='wf-loader'></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">

                        { !isFetchingSessions &&
                            <div className="wf-col-12">
                                <SessionsTable 
                                    data={sessions}
                                />
                            </div>
                        }

                        { isFetchingSessions &&
                            <div className="wf-col-12">
                                <div className="wf-loader" />
                            </div>
                        }

                    </div>
                </div>

                <Footer />


            </div>
        );
    }
}

export default main(UserDetail, {
    is_private: true,
});