import React, { Component } from 'react';
import main from '../../../hoc/main';

import LoginWrapper from '../components/Login';

class LoginContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <LoginWrapper history={this.props.history} />
    }
}

export default main(LoginContainer, {
    is_private: false,
});