import { unique_id } from '../utils';

const projects = [{
    name: 'Motor model ',
    project_reference: unique_id(),
    published_at: '2022-12-01T00:00:00Z',
    created_at: '2022-11-10T00:00:00Z',
    version: 3,
    description: 'This model is a vanilla model that is used to price on price comparison websites.'
}];

export default projects;