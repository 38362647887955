import { each, find, get, omit, isUndefined, endsWith } from 'lodash';

const {
    evaluateSchema,
    inputSchema,
    outputSchema,
    stepTransformSchema,
    stepLabelSchema, 
    stepLinksSchema,
    stepExclusionSchema,
    stepReferSchema,
    stepExcessesSchema,
    stepEndorsementsSchema,
    stepCalculationSchema,
    stepExternalSchema,
    stepFactorsSchema,
} = require('@swa_llow/pricing_engine');

/**
 * This sets the expectations of each step. 
 * New steps need to be defined in here
 */

function output({ quote, key, type, def }) {
    let output = get(quote, `debug.steps.${key}.output`);
    if (output) {
        return {
            [key]: {
                type,
                value: output[key],
            }
        }
    } else {
        return {
            [key]: {
                value: def,
                type: type,
            }
        }
    }
}

function format_output({ format, key, quote }) {
    let result = {};
    let output = get(quote, `debug.steps.${key}.output`);
    if (output) {
        each(format, (v, k) => {
            result[k] = {
                value: output[k],
                type: v.type,
            }
        });
    } else {
        each(format, (v, k) => {
            result[k] = {
                value: v.def,
                type: v.type,
            }
        });
    }
    return result;
}

function links_output({ links, key, quote, type }) {
    let result = {};
    let output = get(quote, `debug.steps.${key}.output`);
    if (output) {
        each(type, (type_value, type_name) => {
            result[type_name] = {
                type: type_value.type,
                value: output[type_name],
            }
        });
    } else {
        each(links.def, (link_value, link_name) => {
            result[link_name] = {
                type: type[link_name].type,
                value: link_value,
            }
        });
    }
    return result;
}

function validate({
    project,
    schema,
}) {
    const result = evaluateSchema({ project, schema });
    return result;
}

function is_completed({
    project,
    schema,
}) {
    const result = evaluateSchema({ project, schema });
    return result.valid;
}

/**
 * {
 * step: step type
 * description: Used in dropdown list builder
 * is_completed: Is used to check if the step has been fully created
 * output: Is the output value and type of the step
 * validate: Is used to validate the step before saving 
 * errors: Is used to return step errors based on supplied quote result (with debug info)
 * timer: Is used to return step execution time based on supplied quote result (with debug info)
 * }
 */

export const steps = [{
    step: 'input',
    description: 'The initial formatting of the quote payload.',
    validate: ({ step }) => {
        return validate({ project: step, schema: inputSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: inputSchema });
    },
    output: ({ step, quote }) => {
        return format_output({ format: step.format, key: 'input', quote });
    },
}, {
    step: 'output',
    description: 'The output formatting of the quote payload.',
    validate: ({ step }) => {
        return validate({ project: step, schema: outputSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: outputSchema });
    },
    output: ({ step, quote }) => {
        return format_output({ format: step.format, key: 'output', quote });
    }
}, {
    step: 'label',
    description: 'Create new quote item from an expression.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepLabelSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepLabelSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: step.def, type: 'string' });
    }
},{
    step: 'transform',
    description: 'Change the quote format and inputs.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepTransformSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepTransformSchema });
    },
    output: ({ step, quote }) => {
        return format_output({ format: step.format, key: step.key, quote });
    }
},{
    step: 'calculation',
    description: 'Create formula to calculate a new quote value.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepCalculationSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepCalculationSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: step.def, type: 'decimal' });
    }
},{
    step: 'exclusion',
    description: 'Create exclusions rules from expressions.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepExclusionSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepExclusionSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: false, type: 'boolean' });
    }
},{
    step: 'excesses',
    description: 'Create excesses rules from expressions.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepExcessesSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepExcessesSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: step.def, type: 'decimal' });
    }
},{
    step: 'refer',
    description: 'Create refer rules from expressions.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepReferSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepReferSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: false, type: 'boolean' });
    }
},{
    step: 'endorsements',
    description: 'Create endorsement rules from expressions.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepEndorsementsSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepEndorsementsSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: step.def, type: 'string' });
    }
},{
    step: 'external',
    description: 'Create API requests that enriches the quote.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepExternalSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepExternalSchema });
    },
    output: ({ step, quote }) => {
        return format_output({ format: step.format, key: step.key, quote });
    }
},{
    step: 'links',
    description: 'Create linked data that enriches the quote.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepLinksSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepLinksSchema });
    },
    output: ({ step, quote }) => {
        return links_output({ links: step.links, key: step.key, quote, type: step.type });
    }
},{
    step: 'factors',
    description: 'Create a factors table that returns a weighted result.',
    validate: ({ step }) => {
        return validate({ project: step, schema: stepFactorsSchema });
    },
    is_completed: ({ step }) => {
        return is_completed({ project: step, schema: stepFactorsSchema });
    },
    output: ({ step, quote }) => {
        return output({ quote, key: step.key, def: step.result.def, type: 'decimal' });
    }
}]
// This needs to the key rather than step type
.map(step => {
    return {
        ...step,
        timer: ({ step, quote }) => {
            const timers = get(quote, `debug.timer`, []);
            const timer = find(timers, t => t.key === step.key || t.key === step.step);
            return omit(timer, ['key']);
        }
    }
})
// This needs to the key rather than step type
.map(step => {
    return {
        ...step,
        errors: ({ step, quote }) => {
            const { key } = step;
            const errors = get(quote, `debug.steps.${key}.errors`, []);
            return errors;
        }
    }
})
// This needs to the key rather than step type
.map(step => {
    return {
        ...step,
        logs: ({ step, quote }) => {
            const { key } = step;
            const logs = get(quote, `debug.steps.${key}`, {});
            return logs;
        }
    }
});

export function getStep(step_name) {
    const step = find(steps, sc => {
        return sc.step === step_name;
    });
    return step || {};
}

export function parseFormattedOutput(output) {
    const obj = {};
    each(output, (v, k) => {
        obj[k] = v.value;
    });
    return obj;
}


/**
 * The returns the built up outputs up until a given step
 */

export function getProgressiveInput ({ project, quote = {}, step_key }) {
    let stop = false;
    let result = getStep('input').output({ step: project.input, quote });
    project.steps.forEach(ps => {
        if (stop) return;
        if (ps.key === step_key) return stop = true;
        const new_output = getStep(ps.step).output({ step: ps, quote });
        // This removed the need of an explict retain = false in the schema 
        // I dont like it
        if (!isUndefined(ps.retain) && ps.retain === false) {
            result = new_output;  
        } else {
            result = {
                ...result,
                ...new_output,
            }
              
        }
    });
    return result;
}