import React, { Component } from 'react';

import {
    Link,
    withRouter,
} from 'react-router-dom';

import routes from '../../../routes';

function is_active_check ({
    link_path = '',
    active_path = '',
}) {
    const needle = active_path.replace('/', '').toLowerCase();
    const haystack = link_path.replace('/', '').toLowerCase();
    return haystack === needle;
}

function makeLinks({
    routes, 
    pathname,
}) {
    
    return routes
        .filter(r => r.hide !== true)
        .map((r, k) => {
            const is_active = is_active_check({
                link_path: r.path,
                active_path: pathname,
            });
            return (
                <Link key={`link-${k}`} to={`${r.path}`} className={`${is_active ? 'current' : ''}`}>
                    <span>{r.name}</span>
                </Link>
            );
        });
}

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang, location, show_nav = true } = this.props;
        const { pathname } = location;

        return (
            <header>
                <h1>A.llow</h1>
                {show_nav &&
                    <nav>
                        {makeLinks({
                            routes,
                            pathname,
                        })}
                    </nav>
                }
            </header>
        );
    }
}

export default withRouter(Header);
