/*
* All routing
*/

import Wireframes from '../components/wireframes/Index';

import AllowPools from '../services/allow/pages/Pools';
import AllowPoolDetail from '../services/allow/pages/PoolDetail';
import AllowUserDetail from '../services/allow/pages/UserDetail';
import AllowCompanyDetail from '../services/allow/pages/Settings';
import AllowUsers from '../services/allow/pages/Users';
import AllowSessions from '../services/allow/pages/Sessions';
import AllowSessionDetail from '../services/allow/pages/SessionDetail';
import AllowSettings from '../services/allow/pages/Settings';
import AllowLogin from '../services/allow/pages/Login';

import GoallowGame from '../services/goallow/pages/Game';

import WillowHome from '../services/willow/pages/Home';

import SwallowLanding from '../services/swallow/pages/Landing';
import SwallowLogin from '../services/swallow/pages/Login';
import SwallowProjects from '../services/swallow/pages/app/Projects';
import SwallowProjectHome from '../services/swallow/pages/app/ProjectHome';
import SwallowProjectData from '../services/swallow/pages/app/ProjectData';
import SwallowProjectLogs from '../services/swallow/pages/app/ProjectLogs';
import SwallowVersionHome from '../services/swallow/pages/app/VersionHome';
import SwallowVersionDesign from '../services/swallow/pages/app/VersionDesign';
import SwallowVersionTests from '../services/swallow/pages/app/VersionTests';

let routes = [        {
    name: 'Wireframes',
    path: '/wf',
    component: Wireframes,
    hide: true,
}];

/*
properties:
name - Link display name
icon - feather icon for display
path - route path. This automatically defined in Router 
product - when to show link with in Policy or Rating view
component - The React Page to render
sublinks - The dropdown children links
hide - if true, this sets up the route but doesn't display it. Use for details pages mainly
*/

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

if (main_component === 'allow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: AllowPools,
        },
        {
            name: 'Login',
            path: '/login/:id',
            hide: true,
            component: AllowLogin,
        },
        {
            name: 'Users',
            path: '/users',
            component: AllowUsers,
        },
        {
            name: 'Sessions',
            path: '/sessions',
            component: AllowSessions,
        },
        {
            name: 'Pools',
            path: '/pools',
            component: AllowPools,
        },
        {
            name: 'PoolDetail',
            path: '/pools/:id',
            component: AllowPoolDetail,
            hide: true,
        },
        {
            name: 'SessionDetail',
            path: '/sessions/:id',
            component: AllowSessionDetail,
            hide: true,
        },
        {
            name: 'UserDetail',
            path: '/users/:id',
            component: AllowUserDetail,
            hide: true,
        },
        {
            name: 'Settings',
            path: '/settings',
            component: AllowSettings,
        },
    ]
};

if (main_component === 'goallow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: GoallowGame,
        },
    ]
};

if (main_component === 'willow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: WillowHome,
        },
    ]
};

if (main_component === 'swallow') {
    routes = [
        ...routes,
        {
            name: 'Landing',
            path: '/',
            component: SwallowLanding,
        },
        {
            name: 'Login',
            path: '/login',
            component: SwallowLogin,
        },
        {
            name: 'Projects',
            path: '/projects',
            component: SwallowProjects,
        },
        {
            name: 'Project Data',
            path: '/project/data/:id',
            component: SwallowProjectData,
        },
        {
            name: 'Project Logs',
            path: '/project/logs/:id',
            component: SwallowProjectLogs,
        },
        {
            name: 'Project Home',
            path: '/project/home/:id',
            component: SwallowProjectHome,
        },
        {
            name: 'Version Home',
            path: '/version/home/:id',
            component: SwallowVersionHome,
        },
        {
            name: 'Version Design',
            path: '/version/design/:id',
            component: SwallowVersionDesign,
        },
        {
            name: 'Version Tests',
            path: '/version/tests/:id',
            component: SwallowVersionTests,
        },
    ]
};

export default routes;
