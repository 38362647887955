import React, { Component } from 'react';
import { isEmpty } from 'lodash';

class StepButtons extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            hide_outputs,
            outputs = [],
            errors = [],
            timer = {},
            logs = {},
        } = this.props;

        return [
            <p style={{display: !hide_outputs ? 'none' : 'flex'}}>
                &rarr; {outputs.length} Output{outputs.length > 1 ? 's': ''}
            </p>,
            <div 
                style={{display: isEmpty(logs) || errors.length !== 0 ? 'none' : 'inline-block'}} 
                className="button secondary small mr" 
                onClick={this.props.openLog}>
                    Step Logs
            </div>,
            <div 
                style={{display: errors.length === 0 ? 'none' : 'inline-block'}} 
                className="button error small mr"
                onClick={this.props.openLog}
                > 
                    {errors.length} Errors
            </div>,
            <div
                style={{display: isEmpty(timer) ? 'none' : 'inline-block'}}  
                className="button grey small no-hover">
                {timer.elapsed} ms
            </div>
        ]
    }
}

export default StepButtons;