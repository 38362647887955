import React, { Component } from 'react';

import main from '../../../hoc/main';

import Video from './landing/Video';
import Features from './landing/Features';

class Home extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
    }

    render() {

        return [
            <div id="swallow" className="splash-container">
                <div className="splash">

                    <header>
                        <div className="logo-container">
                            <h1 className="logo">
                                <span>Swallow - Next generation pricing engine. Make pricing models like software.</span>
                            </h1>
                        </div>
                        <div className="get-swallow">
                            <a href="https://23vn6ewhski.typeform.com/to/yEOnGaIO" target="_blank" className="button big" onClick={this.submit}>Try Swallow</a>
                        </div>
                    </header>

                    <section>
                        <h2>Pricing platform <br/> for Insurtechs<div>What is a pricing platform?</div></h2>
                        <hr style={{visibility: 'hidden'}} />
                        <h3><u><b>Create</b></u> and <u><b>publish</b></u> pricing models like software <div>Why would I want to do that?</div> <span className="rocket"></span>.</h3>

                        <p className="tagline">Make winning pricing models with a smart low-code platform. <br/>So you can win and retain valued customers.<div>Show me the benefits!</div></p>
                    </section>

                    <Video />

                    <Features />



                </div>

                <footer>
                    <a href="https://23vn6ewhski.typeform.com/to/sh3QOarR" target="_blank" className="button small secondary">Contact Us</a>
                    <a href="https://www.linkedin.com/company/91625072" target="_blank" className="button small secondary">LinkedIn</a>
                    <p className="copyright">Copyright Llow Group Ltd (trading as Swallow) &copy; 2022-2023. All rights reserved.</p>
                </footer>
                
            </div>,
        ];
    }
}

export default main(Home, {
    is_private: false,
});