import React, { Component } from 'react';
import { find } from 'lodash';

import StepWrapper from './Step/StepWrapper';
import ActualResult from '../../components/Test/ActualResult';

import { test_runner } from '../../utils/runner';

class DesignArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hide_outputs: true,
            is_logs_open: false,
            is_running: false,
            selected_test: null,
        }
        this.runTest = this.runTest.bind(this);
        this.updateIsLogOpen = this.updateIsLogOpen.bind(this);
        this.hideOutputs = this.hideOutputs.bind(this);
        this.clearSelectedTest = this.clearSelectedTest.bind(this);
    }

    async runTest (test_id) {
        const { tests } = this.props.project;
        const test = find(tests, t => t.id === test_id);
        this.setState({ is_running: true });
        const result = await test_runner({
            project: this.props.project,
            test,
            debug: true,
        });
        this.setState({ 
            selected_test: result,
            is_running: false, 
        });
    }

    clearSelectedTest () {
        this.setState({
            hide_outputs: true,
            selected_test: null,
        })
    }

    updateIsLogOpen() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
        })
    }

    hideOutputs() {
        this.setState({
            hide_outputs: !this.state.hide_outputs,
        })
    }

    render() {
        const { is_logs_open, is_running, selected_test, hide_outputs  } = this.state;
        const { steps = [], project = {} } = this.props;
        const { tests, input, output} = project;
        return (
            <section className="main-content slide-left">
                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <a href="#" className="button grey big" onClick={this.hideOutputs}>
                            {hide_outputs ? 'Show Outputs' : 'Hide Outputs'}
                        </a>
                    </div>

                    <div className="design-button-area-right">

                        {selected_test &&
                            <a href="#" className="button grey big mr" onClick={this.clearSelectedTest}>
                                Clear Test Data
                            </a>
                        }

                        <a href="#" className="button secondary big" onClick={this.props.saveVersion}>
                            Save Design
                        </a>

                    </div>
                </div>

                <div className="design-area">
                    <div className="design-area-left" style={{ display: is_logs_open ? 'none' : 'block' }}></div>
                    <div className="design-area-center" style={{ display: is_logs_open ? 'none' : 'block' }}>
                        <StepWrapper
                            hide_outputs={hide_outputs}
                            quote={selected_test}
                            step={{
                                ...input,
                                key: 'input',
                                step: 'input'
                            }}
                            index={0}
                            id={'input'}
                            addComponent={this.props.addComponent} 
                            updateComponent={this.props.updateComponent} 
                            editComponent={this.props.editComponent}
                        />

                        {steps.map((s, i) => {
                            return (<StepWrapper
                                hide_outputs={hide_outputs}
                                quote={selected_test}
                                step={s}
                                index={i}
                                id={s.id}
                                addComponent={this.props.addComponent} 
                                updateComponent={this.props.updateComponent} 
                                editComponent={this.props.editComponent}
                            />);
                        })}

                        <StepWrapper
                            hide_outputs={hide_outputs}
                            quote={selected_test}
                            step={{
                                ...output,
                                key: 'output',
                                step: 'output'
                            }}
                            index={0}
                            id={'output'}
                            addComponent={this.props.addComponent} 
                            updateComponent={this.props.updateComponent} 
                            editComponent={this.props.editComponent}
                        />
                    </div>

                    <div className="design-area-right">
                        <div className="edit-inputs"> 
                            <form>
                                <fieldset>
                                    <label>Run Test Scenario</label>
                                    <select 
                                        value={(selected_test || {}).id || ''} 
                                        onChange={(e) => {
                                            if(e.target.value == '') return;
                                            this.runTest(e.target.value);
                                        }}>
                                        <option value={''}>Pick Test to Run</option>
                                        {tests.map(t => {
                                            return (<option value={t.id}>{t.name}</option>);
                                        })}
                                    </select>
                                </fieldset>
                            </form>
                            {(selected_test || is_running) &&
                                <ActualResult
                                    quote={selected_test}
                                    is_running={is_running}
                                    updateIsLogOpen={this.updateIsLogOpen}
                                /> 
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default DesignArea;