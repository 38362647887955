const env = (() => {
    const url = window.location.href;
    if (url.includes('localhost:')) return 'dev';
    if (url.includes('test.') || url.includes('test-')) return 'test';
    return 'production';
})();

let config = {
    dev: {
        root: 'http://localhost:33333',
        allow: {
            root: 'http://localhost:3002',
            pool_reference: '098ae4f2',
            poll_timeout: 30000,

        },
        swallow: {
            url: 'http://localhost:3005',
            pool_reference: '098ae4f2',
        },
        goallow: {
            root: 'http://localhost:3003', 
        },
    },
    test: {
        root: 'https://test.api.llow.io',
        allow: {
            root: 'https://test.a.llow.io',
            pool_reference: '098ae4f2',
            poll_timeout: 300000,
        },
        swallow: {
            url: 'https://test.swal.llow.io', 
            pool_reference: '098ae4f2',
        },
        goallow: {
            root: 'https://test.goa.llow.io', 
        },
    },
    prod: {
        root: 'https://api.llow.io',
        allow: {
            root: 'https://a.llow.io',
            pool_reference: '098ae4f2',
            poll_timeout: 300000,
        },
        swallow: {
            url: 'https://swal.llow.io',
            pool_reference: '098ae4f2',
        },
        goallow: {
            root: 'https://goa.llow.io', 
        },
    } 
}

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

if (!main_component) throw console.error('No main_component defined in config.js');

let base = {
    ...config.dev[main_component],
    root: config.dev.root,
    // need for auth logins in each service
    allow: config.dev.allow,
};

if (env === 'test') {
    base = {
        ...config.test[main_component],
        root: config.test.root,
        // need for auth logins in each service
        allow: config.test.allow,
    };
}

if (env === 'production') {
    base = {
        ...config.prod[main_component],
        root: config.prod.root,
        // need for auth logins in each service
        allow: config.prod.allow,
    };
}

export default base;