import React, { Component } from 'react';
import main from '../../../hoc/main';

import { 
    WFInput,
    WFSelect
} from '../../../components/wireframes/modules';

import {
    sign_out
} from '../../../auth/index';

import Header from '../components/Header';
import Footer from '../components/Footer';

import PaymentsTable from '../components/PaymentsTable';

class CompanyDetail extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const { selected = {} } = this.props.auth;
        const { company_reference } = selected;
        await this.props.allow_companys_get({
            id: company_reference,
        });
    }

    render() {
        const { isFetching, selected: company = {} , error  } = this.props.allow_companys;
        return (
            <div>
                <Header />

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <div className="wf wf-bg">
                                <button>Delete Account</button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-6">

                            <div className="wf wf-bg">
                                <button onClick={() => {
                                   sign_out();
                                }}>Log out</button>
                            </div>

                            <hr/>

                            {company && company.is_premium != true &&
                                <div className="wf wf-br">
                                    <p><b>Account Type</b> <br/> Free</p>
                                    <button>Upgrade Account</button>
                                </div>
                            }

                            {company && company.is_premium == true &&
                                <div className="wf wf-br">
                                    <p><b>Account Type</b> <br/> Premium</p>
                                    <p><b>Next Bill</b> <br/> {company.premium_expiry_at}</p>
                                    <button>Downgrade Account</button><br/>
                                    <button>View Payments</button>
                                </div>
                            }

                            <hr/>

                            <div className="wf wf-br">
                                <p><b>Primary Background Colour</b> <br/> {company.primary_bk_colour}</p>
                                <p><b>Primary Text Colour</b> <br/> {company.primary_txt_colour}</p>
                                <p><b>Secondary Background Colour</b> <br/> {company.secondary_bk_colour}</p>
                                <p><b>Secondary Text Colour</b> <br/> {company.secondary_txt_colour}</p>
                            </div>
                            
                        </div>
                        <div className="wf-col-6">
                            {!isFetching && company &&
                                <div className="wf wf-br">
                                    <WFInput 
                                        name={'company_name'}
                                        value={company.company_name}
                                        label={'Company Name'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />

                                    <hr/>
                                    
                                    <WFInput 
                                        name={'address_line_1'}
                                        value={company.address_line_1}
                                        label={'Address Line 1'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                    <WFInput 
                                        name={'address_line_2'}
                                        value={company.address_line_2}
                                        label={'Address Line 2'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                    <WFInput 
                                        name={'address_line_3'}
                                        value={company.address_line_3}
                                        label={'Address Line 3'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                    <WFInput 
                                        name={'address_postcode'}
                                        value={company.address_postcode}
                                        label={'Address Postcode'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                    <WFInput 
                                        name={'address_city'}
                                        value={company.address_city}
                                        label={'Address City'}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { console.log(value) }}
                                    />
                                
                                    <button>Save Company</button>
                                </div>
                            }

                            {isFetching &&
                                <div className="wf wf-br">
                                    <div className='wf-loader'></div>
                                </div>
                            }

                            <hr />
                            
                            <div className="wf wf-bg">
                                <WFInput 
                                    name={'cognito_client_id'}
                                    value={company.cognito_client_id}
                                    label={'Cognito Client ID'}
                                    validation={(value) => value.length > 5}
                                    submit={(value)=> { console.log(value) }}
                                />
                                <WFInput 
                                    name={'cognito_pool'}
                                    value={company.cognito_pool}
                                    label={'Cognito Pool ID'}
                                    validation={(value) => value.length > 5}
                                    submit={(value)=> { console.log(value) }}
                                />
                                <button>Save Cognito Details</button>
                                
                            </div>

                            <hr />

                            <div className="wf wf-bg">
                                <WFSelect 
                                    name={'payment_provider'}
                                    items={[
                                        {
                                            name: 'Stripe',
                                            value: 'stripe',
                                        }
                                    ]}
                                    value={company.payment_provider}
                                    label={'Payment Provider'}
                                    submit={(value)=> { console.log(value) }}
                                />
                                <WFInput 
                                    name={'payment_provider_customer_ref'}
                                    value={company.payment_provider_customer_ref}
                                    label={'Payment Customer Reference'}
                                    validation={(value) => value.length > 5}
                                    submit={(value)=> { console.log(value) }}
                                />
                                <button>Save Payment Details</button>
                            </div>
                        </div>
                    </div>
                </div>


                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <PaymentsTable />
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default main(CompanyDetail, {
    is_private: true,
});