import React, { Component } from 'react';
import config from '../../../config';

import Login from '../../../auth/Login';

class LoginWrapper extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { pool_reference, url } = config;
        return (
            <div>
                <div className="login-container">
                    <div className="logo-container small">
                        <h1 className="logo"></h1>
                    </div>
                    <Login 
                        history={this.props.history} 
                        pool_reference={pool_reference}
                        success_url={`${url}/projects`}
                    />
                </div>
            </div>
        );
    }
}

export default LoginWrapper;