import { unique_id } from '../utils';

const version = {
    meta: {
        name: 'Motor Model',
        project_reference: unique_id(),
        version_reference: unique_id(),
    },
    input: {
        schema : {
            postcode: {
                type: 'text',
                value: 'PO343TR'
            },
            reg: {
                type: 'text',
                value: 'WO13BHZ'
            },
            name: {
                type: 'text',
                value: 'Rimmer',
            },
            children: {
                type: 'number',
                value: 0
            },
            email: {
                type: 'text',
                value: 'cal@cal.com',
            },
            age: {
                type: 'number',
                value: 43
            },
            gender: {
                type: 'enum',
                value: 'M',
                items: ['M', 'F', 'U']
            },
            smokes: {
                type: 'boolean',
                value: false,
            },
        },
        indicators : [
            'email',
            'postcode',
            'reg',
            'name',
        ]
    },
    output: {},
    steps: [{ 
        step: 'label', 
        name: 'Add postcode category',
        id: unique_id(),
    },{ 
        step: 'exclusions', 
        name: 'Default expressions',
        items: [{
            id: unique_id(),
            name: 'Too Young',
            expression: '{age} < 21' 
        },{
            id: unique_id(),
            name: 'Too Old',
            expression: '{age} > 80' 
        }],
        id: unique_id(),
    },{ 
        step: 'calculate', 
        name: 'Default formula',
        data: {},
        id: unique_id(),
    },{ 
        step: 'transform', 
        name: 'Remove unused items from XML conversion',
        id: unique_id(),
    },{
        step: 'model', 
        name: 'Motor Model for PCWs',
        id: unique_id(),
    }],

    // Tests - likely exist outside version
    tests: [{
        name: 'First Test',
        expected: {
            result: '200.0000',
            valid: true,
        },
        result: {
            result: '200.0000',
            valid: true,
        },
        id: unique_id(),
    },{
        name: 'Second test',
        expected: {
            result: '201.0000',
            valid: false,
        },
        result: {
            result: '200.0000',
            valid: true,
        },
        id: unique_id(),
    },{
        name: 'Third test',
        expected: {
            result: '202.0000',
            valid: true,
        },
        result: {
            result: '200.0000',
            valid: true,
        },
        disabled: true,
        id: unique_id(),
    },{
        name: 'Fourth Test',
        expected: {
            result: '200.0000',
            valid: false,
        },
        result: {
            result: '200.0000',
            valid: false,
        },
        id: unique_id(),
    }],

};

export default version;