import React, { Component } from 'react';
import main from '../../../hoc/main';
import { first } from 'lodash';

import { 
    WFInput, 
    WFSelect, 
    WFCode
} from '../../../components/wireframes/modules';

import {
    Link,
} from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import PoolCard from '../components/PoolCard';

class Pools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pool_name: null,
        }
    }

    async componentDidMount() {
        await this.props.allow_pools_get_all();
    }

    render() {

        const {
            error,
            isFetching,
            data = [],
        } = this.props.allow_pools;

        const admin = first(data.filter(d => d.is_admin) || []);
        const non_admin = data.filter(d => !d.is_admin) || [];

        function validate_pool_name (pool_name) {
            if (data.map(d => d.pool_name).includes(pool_name)) return false;
            if ((pool_name || '').length < 5) return false;
            return true;
        }

        return (
            <div>
                <Header />

                <hr />

                <div className="wf-container">
                    {error &&
                        <div className="wf-row">
                            <div className="wf-col-12">
                                <div className='wf wf-bg'>
                                    {error.message}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="wf-row">

                        <div className="wf-col-4">
                            { isFetching && <div className="wf-loader"></div>}
                            { !isFetching && data.length > 0 && <PoolCard data={admin} />}
                        </div>

                        <div className="wf-col-8">
                            <div className="wf wf-br">
                                <WFInput 
                                    name={'pool_name'}
                                    label={'New Pool Name'}
                                    validation={(value) => {
                                        return validate_pool_name(value);
                                    }}
                                    submit={(value)=> {
                                        this.setState(value); 
                                    }}
                                />
                                <button onClick={() => {
                                    const { pool_name } = this.state;
                                    if(validate_pool_name(pool_name)){
                                        this.props.allow_pools_post({ data: {
                                            pool_name 
                                        }});
                                    }
                                }}>Create Pool</button>
                            </div>

                            {non_admin.map(data => {
                                return [<hr/>, <PoolCard data={data}/>]
                            })}

                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default main(Pools, {
    is_private: true,
});