import React from 'react';

function Table (props) {
    
    const {
        data = [],
        answers = {},
        update_answers = () => {},
    } = props;

    return (
        <div className="card international">
            <table>
                <thead>
                    <tr>
                        <th>Years</th>
                        <th>Team</th>
                        <th>Apps</th>
                        <th>Goals</th>
                        <th>{ answers.international && <button className="active show_number">-1</button>}</th>
                    </tr>
                </thead>
                <tbody>
                    { data.length === 0 && 
                        <tr>
                            <td className="no-data" colSpan={5}><b>No International Caps</b></td>
                        </tr>
                    }

                    { !answers.international && 
                        <tr className='strike'>
                            <td>
                                <b>-</b>
                            </td>
                            <td>
                                <b>-</b>
                            </td>
                            <td>
                                <b>-</b>
                            </td>
                            <td>
                                <b>-</b>
                            </td>
                            <td className="button">
                                <button className='' onClick={() => update_answers({
                                    type: 'international',
                                    data: true,
                                })}>?</button>
                            </td>
                        </tr>
                    }
                    { answers.international && data.map((c, i) => {
                        return (
                            <tr key={`${i}-clubs-${c.years}`}>
                                <td>
                                    <span>{c.years}</span>
                                </td>
                                <td>
                                    <b>{c.club}</b>
                                </td>
                                <td>
                                    <b>{c.app}</b>
                                </td>
                                <td>
                                    <b>{c.goals}</b>
                                </td>
                                <td className="button"></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Table;