import React, { Component } from 'react';
import { each } from 'lodash';
import BooleanSelect from './BooleanSelect';

function map_each ({ 
    inputs,
}) {
    let items = [];
    each(inputs, (v, k) => {
        items.push({
            key: k,
            value: v.value,
            type: v.type,
        });
    })
    return items;
}

class Inputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: this.props.inputs,
        }
        this.updateInputValue = this.updateInputValue.bind(this);
    }

    updateInputValue ({
        key, 
        value,
        type,
    }) {
        this.props.updateInputValue({
            [key] : {
                value,
                type,
            } 
        });
    }   

    render() {
        const { is_output = false } = this.props;
        const inputs = map_each({ inputs: this.props.inputs });
        const is_disabled = is_output;

        return (
           <div className={`input-wrapper ${is_disabled ? '' : 'editable'}`}>
               
                <div className="input_row header">
                    <div className="input_key">{is_output ? 'Output' : 'Input'}</div>
                    <div className="input_value"><b>Value</b></div>
                </div>

                {inputs.map(input => {
                    if (input.type === 'boolean') {
                        return (
                            <div className="input_row">
                                <div className="input_key">{input.key}</div>
                                <div className="input_value">
                                    <BooleanSelect
                                        value={input.value}
                                        update={({ value }) => {
                                            this.updateInputValue({
                                                type: input.type,
                                                key: input.key,
                                                value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }

                    // TODO needs to deal with enums
                    return (
                        <div className="input_row">
                            <div className="input_key">{input.key}</div>
                            <div className="input_value">
                                <input 
                                    type={input.type === 'string' ? 'text' : 'number'} 
                                    disabled={is_disabled}
                                    value={input.value} 
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (input.type !== 'string') value = Number(value);
                                        this.updateInputValue({
                                            type: input.type,
                                            key: input.key,
                                            value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default Inputs;