import React, { Component } from 'react';
import { omit } from 'lodash';

import main from '../../../../hoc/main';

import TopNav from '../../components/Layout/TopNav';
import TestResult from '../../components/Test/TestResult';
import TestList from '../../components/Test/TestList';

import { getId } from '../../../../utils/url';
import { unique_id } from '../../utils';
import {
    getStep,
    parseFormattedOutput,
} from '../../utils/steps';

import {
    test_runner,
} from '../../utils/runner';

class VersionTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: [],
            selected_test_id: null,
        }
        this.updateTestDetail = this.updateTestDetail.bind(this);
        this.updateTestList = this.updateTestList.bind(this);
        this.runner = this.runner.bind(this);
        this.runTests = this.runTests.bind(this);
        this.runTest = this.runTest.bind(this);
        this.viewTest = this.viewTest.bind(this);
        this.fixTests = this.fixTests.bind(this);
        this.createTest = this.createTest.bind(this);
        this.deleteTest = this.deleteTest.bind(this);
        this.refreshVersion = this.refreshVersion.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
    }

    async componentDidMount() {
        const { selected:version } = this.props.swallow_versions;
        await this.refreshVersion(version);
    }

    async saveVersion() {
        const id = getId(this.props.history);
        const { selected:version } = this.props.swallow_versions;
        const { tests } = this.state;
        await this.props.swallow_versions_put({
            id, 
            data: {
                project: {
                    ...version,
                    tests,
                }
            }
        });
    }

    async refreshVersion(version) {
        const id = getId(this.props.history);
        if(!version) {
            await this.props.swallow_versions_get({
                id, 
            });
        }
        const { tests } = this.props.swallow_versions.selected;
        this.setState({
            tests,
        }, async() => {
            // TODO - should run once on page load then only check on manual request 
            await this.runTests(tests);
        });
    }

    createTest() {
        const { tests } = this.state;
        const { selected:version } = this.props.swallow_versions;

        // Sets inputs to defaults
        const inputs = getStep('input').output({ step: version.input });
        
        const new_test = {
            id: unique_id(),
            name: '>>> New Test (with Defaults) <<<',
            key: 'new_test_with_defaults',
            input: parseFormattedOutput(inputs),
            output: {
                result: 0,
                valid: true,
            },
        };
        this.setState({
            tests: [ new_test, ...tests ],
        }, () => {
            this.runTest(new_test);
        })
        
    }

    async viewTest (test) {
        if (!test) {
            this.setState({
                selected_test_id: null,
            });
        } else {
            await this.runTests([test], true);
            this.setState({
                selected_test_id: test.id,
            });
        }
    }

    async deleteTest (test_id) {
        const { tests } = this.state;
        this.setState({ selected_test_id: null });
        this.setState({
            tests: tests.filter(t => t.id !== test_id),
        }, async() => {
            await this.saveVersion();
        });
    }

    async fixTests () {
        const { tests } = this.state;
        this.setState({
            tests: tests.map(t => {
                return {
                    ...t,
                    output: {
                        result: t.result.result,
                        valid: t.result.valid,
                    }
                }
            })
        }, () => {
            this.saveVersion();
        });
    }

    async updateTestDetail (data) {
        const { tests } = this.state;
        const new_tests = tests.map(t => {
            if (t.id === data.id) {
                return data;
            }
            return t;
        })
        this.setState({ 
            tests: new_tests,
        }, async() => {
            await this.saveVersion();
            this.setState({ selected_test_id: null });
        });
    }

    updateTestList ({ data }) {
        this.setState({ 
            tests: data.items
        }, () => {
            this.saveVersion();
        });
    }

    async runner(test, debug) {
        const { selected:version } = this.props.swallow_versions; 
        const { tests: all_tests } = this.state;

        const response = await test_runner({
            project: version,
            test,
            debug,
        });

        const new_tests = all_tests.map(t => {
            if (t.id === test.id) {
                return response;
            }
            return t;
        });

        this.setState({
            tests: new_tests,
        });

        return response;
    }

    async runTest(run_test = {}, debug = true) {
        const new_tests = this.state.tests.map(t => {
            if (t.id === run_test.id) return omit(t, ['result', 'debug']);
            return t;
        });
        this.setState({ tests : new_tests });
        const result = await this.runner(run_test, debug);
    }

    async runTests(run_tests = [], debug = false) {
        const { tests } = this.state;
        this.setState({ tests : tests.map(t => omit(t, ['result', 'debug'])) });
        for (const test of run_tests) {
            await this.runner(test, debug);
        }
    }

    render() {

        const { 
            isFetching: isFetchingVersions = false,
            selected: version,
        } = this.props.swallow_versions || {};

        const { tests = [], selected_test_id } = this.state;

        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} />

                {!isFetchingVersions && selected_test_id &&
                    <TestResult
                        selected_test_id={selected_test_id}
                        tests={tests}
                        viewTest={this.viewTest}
                        deleteTest={this.deleteTest}
                        runTest={this.runTest}
                        project={version}
                        updateTests={this.updateTestDetail}
                    />
                }

                {!isFetchingVersions && !selected_test_id &&
                    <TestList 
                        tests={tests}
                        runTest={this.runTest}
                        runTests={this.runTests}
                        viewTest={this.viewTest}
                        fixTests={this.fixTests}
                        createTest={this.createTest}
                        updateTests={this.updateTestList}
                    />
                }

            </div>
        );
    }
}

export default main(VersionTests, {
    is_private: true,
});