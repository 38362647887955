import React, { Component } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { each } from 'lodash';

function map_each ({ 
    inputs,
}) {
    let items = [];
    each(inputs, (v, k) => {
        items.push({
            name: k,
            char: `{{${k}}}`,
        });
    })
    return items;
}

class ExpressionTextarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: this.props.inputs,
        }
        this.updateInputValue = this.updateInputValue.bind(this);
    }

    updateInputValue ({
        key, 
        value,
        type,
    }) {
        this.props.updateInputValue({
            [key] : {
                value,
                type,
            } 
        });
    }   

    render() {
        const inputs = map_each({ inputs: this.props.inputs });
        const { value } = this.props;

        return (
            <ReactTextareaAutocomplete
                value={value}
                className="expression-textarea"
                onChange={(e) => this.props.updateExpression(e.target.value)}
                loadingComponent={() => <span>Loading</span>}
                trigger={{
                    "{{": {
                        dataProvider: token => {
                            return inputs.filter(item => item.char.includes(token));
                        },
                        component: ({ entity: { name, char } }) => (<div>{`${char}`}</div>),
                        output: (item) => item.char,
                    }
                }}
            />
        );
    }
}

export default ExpressionTextarea;