import React, { Component } from 'react';

import TestTable from '../../components/Test/TestTable';

class TestList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tests = [] } = this.props;

        const total_count = tests.filter(t => t.result).length;

        const passed_count = tests.filter(t => t.result).filter(t => {
            return t.result.result === t.output.result && t.result.valid === t.output.valid;
        }).length;

        const failed_count = tests.filter(t => t.result).filter(t => {
            return t.result.result !== t.output.result || t.result.valid !== t.output.valid;
        }).length;

        const running_count = total_count - passed_count - failed_count;

        return (
            <section className="main-content slide-left">
                
                <div className="design-button-area">
                    <div className="design-button-area-left">

                        {/*a href="" className="button grey big mr">
                            Import Tests
                        </a>*/}

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.fixTests();
                        }} className="button grey big">
                            Fix All Tests
                        </a>
                    </div>
                    <div className="design-button-area-right">

                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.createTest();
                        }} className="button secondary big mr">
                            Create Test
                        </a>
                        
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.runTests(tests);
                        }} className="button secondary big icon">
                            <span class="play"></span>
                            Run All Tests
                        </a>

                    </div>
                </div>

                <div className="test-area">
                    <div className="test-area-left">
                        <TestTable
                            runTest={this.props.runTest}
                            viewTest={this.props.viewTest}
                            componentData={{
                                items: tests,
                            }}
                            updateComponent={this.props.updateTests}
                            placeholder={`Search Tests`}
                            headers={[
                                'Name',
                                'Expected',
                                'Result',
                            ]} 
                        />
                    </div>
                    <div className="test-area-right">
                        <div className="test-area-results">
                            <h2><span>{tests.length}</span> Tests</h2>
                            <div className="test-area-result-list">
                                <div className="button tertiary">{passed_count} Passed</div>
                                <div className="button error">{failed_count} Failed</div>
                                <div className="button grey">{running_count} Running</div>
                            </div>
                        </div>
                        <div className="test-area-chart">
                            <p>Chart</p>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default TestList;