import React, { Component } from 'react';
import { extendedDateFormat } from '../../../utils/format';

import {
    Link,
} from 'react-router-dom';

class PaymentsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang, data = [] } = this.props;
        return (
            <table>
                <thead>
                    <tr>
                        <th>Payment Reference</th>
                        <th>Amount</th>
                        <th>Success Date</th>
                        <th>Issue Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>3941eb25-5df1-4aee-be76-fb0a6a432b73</td>
                        <td>£12.00</td>
                        <td>18:00 23/12/2022</td>
                        <td>18:00 23/12/2022</td>
                    </tr>
                    <tr>
                        <td>3941eb25-5df1-4aee-be76-fb0a6a432b73</td>
                        <td>£12.00</td>
                        <td>18:00 23/12/2022</td>
                        <td>18:00 23/12/2022</td>
                    </tr>
                    <tr>
                        <td>3941eb25-5df1-4aee-be76-fb0a6a432b73</td>
                        <td>£12.00</td>
                        <td>18:00 23/12/2022</td>
                        <td>18:00 23/12/2022</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default PaymentsTable;
