import {
    getStep,
    parseFormattedOutput,
} from './steps';

import { engine } from '@swa_llow/pricing_engine';

import { omit } from 'lodash';

export function test_response ({ test, result, debug }) {
    return {
        ...test,
        result: omit(result, 'debug'),
        debug: debug ? result.debug : {},
    }
}

export async function test_runner({
    test, 
    project,
    debug = false,
}) {

    // Default if no input value set yet
    // This stops the engine failing as we skip the input step on is_test=true
    const defaultInputsFormatted = getStep('input').output({ step: project.input });
    const defaultInputs =  parseFormattedOutput(defaultInputsFormatted);
    const quote = {
        ...defaultInputs,
        ...test.input,
    }

    const result = await engine({
        project,
        quote,
        is_test: true,
    });

    const response = test_response({
        result,
        test,
        debug,
    });

    return response;
}