import React, { Component } from 'react';
import main from '../../../../hoc/main';

import TopNav from '../../components/Layout/TopNav';

class ProjectHome extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
    }

    render() {

        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} />
                
                <h2>Project Home</h2>

            </div>
        );
    }
}

export default main(ProjectHome, {
    is_private: true,
});